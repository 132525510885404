import { createAsyncThunk } from '@reduxjs/toolkit';
import { AlloyApi } from 'api/AlloyApi';

export const createAlloyKycThunk = (api: AlloyApi) =>
  createAsyncThunk('kyc/check', async (applicationId: string, thunkApi) => {
    try {
      return await api.check(applicationId);
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
