import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';

import { ErrorResponse } from './ErrorResponse';

export const createUpdateApplicationCheckingAccountThunk = (api: ApplicationDataApi) =>
  createAsyncThunk(
    'applicationData/updateUserBankAccountData',
    async (data: { applicationId: string; bankAccountId: string }, thunkApi) => {
      try {
        await api.updateApplicationCheckingAccount(data.applicationId, data.bankAccountId);
      } catch (error: unknown) {
        thunkApi.rejectWithValue((error as ErrorResponse).response.data);
      }
    },
  );
