import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootReducer from 'handlers';
import saveApplicationStepMiddleware from 'middlewares/saveState';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';
import { CurrentFlow } from 'enums/CurrentFlow';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';
import { FLOW_ARG_TO_CURRENT_FLOW } from 'utils/flowArgToCurrentFlow';
import { UrlParams } from 'enums/UrlParams';

export const history = createBrowserHistory();

const urlSearchParams = new URLSearchParams(window.location.search);

const flowParam = urlSearchParams.get(UrlParams.Flow);

const defaultFlows = Object.values(CurrentFlow) as CurrentFlow[];

const possibleFlows = (flowParam !== null ? [FLOW_ARG_TO_CURRENT_FLOW[flowParam]] : defaultFlows) ?? defaultFlows;

const persistedState = getLocalStorageItem(LocalStorageKeyName.ApplicationState);

const initialState =
  urlSearchParams.get(UrlParams.ResumeProcess) || !possibleFlows.includes(persistedState?.applicationStep.currentFlow)
    ? // Clear all state except for auth since we need it to make protected backend calls.
      { auth: persistedState?.auth }
    : persistedState;

// TODO, temporary fix, remove when downstate done
if (initialState?.preQualificationData?.partnerName) {
  initialState.preQualificationData.partnerName = initialState.preQualificationData.partnerName.toLowerCase();
}

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), saveApplicationStepMiddleware],
  preloadedState: initialState || undefined,
});

export type AppDispatch = typeof store.dispatch;

/**
 * If you want to access store state directly use getInjectedStoreState() function in `injectStore.ts` instead of
 * importing store directly: https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */
export default store;
