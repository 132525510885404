import React from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import { FlowComponentType } from 'routes/types';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ButtonType } from 'components/Button/Button';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';

import styles from './PaymentInitiated.module.scss';

const PaymentInitiated = ({ navigationInfo }: FlowComponentType) => {
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { missedPaymentAmount, bankName } = application;

  const handleBack = () => {
    window.location.href = process.env.REACT_APP_PLANNERY_LANDING_URL ?? '';
  };

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <CheckIcon />
        </div>
        <div className={styles.title}>
          We’ve initiated a payment of {formatMonetaryAmount(missedPaymentAmount)} from your {bankName} account.
        </div>
        <div className={styles.description}>
          Funds may take up to 3 business days to transfer. We will keep you updated on its progress.
        </div>
        <Button type={ButtonType.Inverted} onClick={handleBack}>
          Back to Home Page
        </Button>
      </div>
    </div>
  );
};

export default PaymentInitiated;
