import { CardApi, CardApplyData, CardApplyWithApplicationData } from 'api/CardApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorResponse } from './ErrorResponse';

export const createCardApplyThunk = (api: CardApi) =>
  createAsyncThunk('card/apply', async (data: CardApplyData, thunkApi) => {
    try {
      return await api.apply(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });

export const createCardApplyWithLoanOfferThunk = (api: CardApi) =>
  createAsyncThunk('card/applyWithApplication', async (data: CardApplyWithApplicationData, thunkApi) => {
    try {
      return await api.applyWithLoanOffer(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
