import React, { useEffect } from 'react';
import { PayrollProviderName } from 'api/EmployersApi';
import { getApplicationData } from 'selectors/getApplicationData';
import { useSelector } from 'react-redux';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';
import { FlowComponentType } from 'routes/types';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { updateApplicationStatus } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import ManualDeduction from './ManualDeduction';
import AutomaticDeduction from './AutomaticDeduction';

const SetupDeduction = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application } = useSelector(getApplicationData);

  const payrollProvider = application?.employment.employer1?.payrollProvider ?? PayrollProviderName.Manual;

  useEffect(() => {
    if (payrollProvider === PayrollProviderName.ManuallySendToHR) {
      (async () => {
        if (application?.status === ApplicationStatusName.SetUpDeduction) {
          await dispatchWithUnwrap(
            updateApplicationStatus({
              applicationId: application!.id,
              applicationStatus: ApplicationStatusName.FinalReview,
            }),
          );
        }
        navigate(RoutePath.OfferStatus);
      })();
    } else if (application?.manualDeductionEmailSent) {
      navigate(RoutePath.VerifyPayrollDeduction);
    }
  }, []);

  return (
    <>
      {[PayrollProviderName.Manual].includes(payrollProvider) && <ManualDeduction />}
      {[PayrollProviderName.Argyle, PayrollProviderName.Pinwheel, PayrollProviderName.Atomic].includes(
        payrollProvider,
      ) && <AutomaticDeduction flags={flags} navigationInfo={navigationInfo} handleNext={handleNext} />}
    </>
  );
};

export default SetupDeduction;
