import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApplicationData, updateEmploymentData } from 'thunks';

import { Employer, GetApplicationDataResponse } from './applicationData';

export interface YourEmployerData {
  employer1: Employer;
  employer2: Employer;
  isLicensedProfessional: boolean;
  additionalIncome?: number;
  isLoading?: boolean;
  healthcareLicenseNumber: string;
  healthcareLicenseState: string;
}

const employerInitialState: Employer = { employerName: '', annualIncome: 0, hireDate: '', jobTitle: '' };

export const initialState: YourEmployerData = {
  employer1: employerInitialState,
  employer2: employerInitialState,
  isLicensedProfessional: false,
  healthcareLicenseNumber: '',
  healthcareLicenseState: '',
  isLoading: false,
};

const yourEmployer = createSlice({
  name: 'yourEmployer',
  initialState,
  reducers: {
    setYourEmployerData: (state: YourEmployerData, { payload }: PayloadAction<YourEmployerData>) => {
      const employer1 = payload.employer1 ? { ...state.employer1, ...payload.employer1 } : state.employer1;
      const employer2 = payload.employer2 ? { ...state.employer2, ...payload.employer2 } : state.employer2;

      analytics.identify({
        employerName1: employer1.employerName,
        employerName2: employer2.employerName,
      });

      return Object.assign(state, {
        employer1,
        employer2,
        isLicensedProfessional: payload.isLicensedProfessional,
        additionalIncome: payload.additionalIncome,
      });
    },
    clearSecondaryEmployer: (state: YourEmployerData) => {
      state.employer2 = initialState.employer2;
      analytics.identify({
        employerName2: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateEmploymentData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateEmploymentData.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateEmploymentData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      const { employment } = payload.application;
      const { employer1, employer2, healthcareLicenseNumber, healthcareLicenseState, isLicensedProfessional } =
        employment ?? {};

      employer1 &&
        (state.employer1 = {
          ...employer1,
        });
      employer2 &&
        (state.employer2 = {
          ...employer2,
        });
      state.healthcareLicenseNumber = healthcareLicenseNumber ?? '';
      state.healthcareLicenseState = healthcareLicenseState ?? '';
      state.isLicensedProfessional = isLicensedProfessional ?? false;
    });
  },
});

export const { setYourEmployerData, clearSecondaryEmployer } = yourEmployer.actions;

export default yourEmployer.reducer;
