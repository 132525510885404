import { createEvent, EventAttributes } from 'ics';

export async function addToCalendar(eventData: EventAttributes) {
  const filename = 'add-to-calendar.ics';
  const file = await new Promise((resolve, reject) => {
    createEvent(eventData, (error, value) => {
      if (error) {
        reject(error);
      }

      resolve(new File([value], filename, { type: 'text/calendar' }));
    });
  });
  const url = URL.createObjectURL(file as Blob);

  // trying to assign the file URL to a window could cause cross-site
  // issues so this is a workaround using HTML5
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}
