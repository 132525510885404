import { Relationship } from 'api/StudentLoanApi';

interface RelationshipOption {
  label: string;
  value: Relationship;
}

export const RELATIONSHIP_OPTIONS: RelationshipOption[] = [
  { label: 'Mother', value: Relationship.Mother },
  { label: 'Father', value: Relationship.Father },
  { label: 'Brother', value: Relationship.Brother },
  { label: 'Sister', value: Relationship.Sister },
  { label: 'Child', value: Relationship.Child },
  { label: 'Friend', value: Relationship.Friend },
  { label: 'Other family member', value: Relationship.OtherFamilyMember },
];
