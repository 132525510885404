import React, { useEffect } from 'react';
import Modal from 'components/Common/Modal';
import Button from 'components/Button';

import styles from './TalkNowModal.module.scss';

type TalkNowProps = {
  onClose: () => void;
  onCall: () => void;
};

const TalkNowModal = ({ onClose, onCall }: TalkNowProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Modal closeModal={onClose}>
      <>
        <p className={styles.title}>Talk Now</p>

        <p className={styles.label}>
          Speak with a student loan expert,
          <br />
          right now!
        </p>

        <a href="tel:1-877-654-9102" className={styles.phone}>
          <Button className={styles.button} onClick={onCall}>
            Call Now!
          </Button>
        </a>
      </>
    </Modal>
  );
};

export default TalkNowModal;
