import React from 'react';
import clsx from 'clsx';

import { ReactComponent as UploadIcon } from 'images/upload-icon.svg';

import { useDropzone } from 'react-dropzone';

import styles from './DropzoneWidget.module.scss';

interface DropzoneProps {
  extensions?: string[];
  multiple?: boolean;
  maxFileSize?: number;
  uploadFileTitle?: string;
  dragAndDropTitle?: string;
  onDragClass?: string;
  handleFiles: (files: File[]) => void;
}

export interface DropzoneFile {
  file: File;
  key: string;
  blobLink: string;
}

const DEFAULT_MAX_FILE_SIZE = 10485760;

const DropzoneWidget = ({
  extensions,
  handleFiles,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  multiple = true,
}: DropzoneProps) => {
  const { open, getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject } = useDropzone({
    accept: extensions,
    noClick: true,
    multiple,
    maxSize: maxFileSize,
    onDrop: (acceptedFiles: File[]) => {
      handleFiles(acceptedFiles);
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.widget} {...getRootProps()}>
        <input className={styles.uploadInput} {...getInputProps()} />
        <div
          className={clsx(styles.dropbox, {
            [styles.dropboxAccept]: isDragAccept,
            [styles.dropboxActive]: isDragActive,
            [styles.dropboxReject]: isDragReject,
          })}
          onClick={open}
        >
          {isDragReject ? (
            <p className={styles.rejectMessage}>Unsupported file format</p>
          ) : (
            <div className={styles.linkContainer}>
              <UploadIcon />
              <span className={clsx(styles.link, styles.linkMobil)}>Browse for a file to upload</span>
              <p className={clsx(styles.link, styles.linkDesktop)}>
                Drag a file here or <span className={styles.linkDesktopHighlighted}>browse for a file</span> to upload
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropzoneWidget;
