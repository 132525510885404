import React, { FC, useState } from 'react';
import clsx from 'clsx';

import styles from './ToggleButtonGroup.module.scss';

export interface ToggleButton<ResultType> {
  value: ResultType;
  selected?: boolean;
  onClick?: (value: ResultType) => void;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  chip?: string;
  children?: JSX.Element;
  expandOnSelect?: boolean;
}

interface Props<ResultType> {
  defaultValue?: ResultType;
  options: ToggleButton<ResultType>[];
  onChange: (value: ResultType) => void;
}

const ToggleButtonGroup: FC<Props<string>> = <ResultType,>({ defaultValue, options, onChange }: Props<ResultType>) => {
  const [selected, setSelected] = useState(defaultValue);

  return (
    <div className={styles.options}>
      {options.map((option: ToggleButton<ResultType>) => (
        <div
          className={clsx(styles.option, { [styles.selected]: selected === option.value })}
          onClick={() => {
            setSelected(option.value);
            onChange(option.value);
          }}
        >
          <p className={clsx(styles.heading, { [styles.hasChip]: Boolean(option.chip) })}>
            {option.title}
            {option.chip && <span className={styles.chip}>{option.chip}</span>}
          </p>
          <p className={styles.subHeading}>{option.subtitle}</p>
          {option.children && (!option.expandOnSelect || (option.expandOnSelect && selected === option.value)) && (
            <div className={styles.description}>{option.children}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;
