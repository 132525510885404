import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDocumentsApi, DataForUploadingDocuments } from 'api/DocumentsApi';

import { ErrorResponse } from './ErrorResponse';

export const createUploadDocumentsThunk = (api: ApplicationDocumentsApi) =>
  createAsyncThunk('documents/upload', async (data: DataForUploadingDocuments, thunkApi) => {
    try {
      return await api.uploadDocuments(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
