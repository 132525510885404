import React, { useEffect } from 'react';
import alloy from '@alloyidentity/web-sdk';
import { useSelector } from 'react-redux';
import { getKyc } from 'selectors/kyc';
import { getApplicationData } from 'selectors/getApplicationData';
import { getEnvironment, Environments } from 'utils/getEnvironment';
import { KycStatus } from 'handlers/kyc';
import PartnerBanner from 'components/Common/PartnerBanner';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { ReactComponent as AlloyLogo } from 'images/alloy.svg';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { ReactComponent as Profile } from 'images/profile.svg';
import { ReactComponent as Secure } from 'images/lock.svg';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { alloyKyc } from 'thunks';
import { FlowComponentType } from 'routes/types';
import FormNavigation from 'components/FormNavigation';

import styles from './Alloy.module.scss';

const descriptionItems = [
  {
    description: 'You’ll be asked to snap photos of the front and back of your license.',
    icon: Profile,
  },
  {
    description: 'Your data is secure and helps us prevent identity theft',
    icon: Secure,
  },
];

enum Status {
  Completed = 'completed',
  WaitingReview = 'waiting_review',
  DataRequest = 'data_request',
  PendingDocuments = 'pending_documents',
  PendingAction = 'pending_action',
  Running = 'running',
}

const Alloy = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isLoading, journeyApplicationToken, journeyToken, status: kycStatus } = useSelector(getKyc);
  const { application } = useSelector(getApplicationData);

  useEffect(() => {
    const run = async () => {
      if (!journeyApplicationToken && application) {
        await dispatchWithUnwrap(alloyKyc(application.id));
      }
    };

    run();
  }, [application]);

  const openAlloy = () => {
    const alloyInitParams = {
      key: process.env.REACT_APP_ALLOY_SDK_KEY!,
      production: getEnvironment() === Environments.Production,
      color: { primary: '#795af7', secondary: '#454b54' },
      journeyApplicationToken,
      journeyToken,
      isNext: true,
    };
    alloy.init(alloyInitParams);
    alloy.open(callback);
  };

  // Callback
  const callback = async (data: any) => {
    console.log(data);
    if (data.status) {
      analytics.track(`Alloy ${data.status}`);
      const { status } = await dispatchWithUnwrap(alloyKyc(application!.id));
      if (data.status !== Status.PendingDocuments) {
        analytics.track('KYC Status', { status });
        handleNext();
      }
    }
  };

  const renderAction = () => {
    if (kycStatus === KycStatus.PendingDocuments) {
      return (
        <Button className={styles.button} onClick={openAlloy} isLoading={!application}>
          Get Started
        </Button>
      );
    }

    if (kycStatus === KycStatus.Approved) {
      return (
        <div className={styles.kycStatusContainer}>
          <CheckIcon className={styles.checkIcon} />
          <p className={styles.verifiedLabel}>Verified</p>
        </div>
      );
    }

    // Any other KycStatus we should do a manual verification
    return (
      <div className={styles.kycStatusContainer}>
        <p className={styles.reviewingDocumentsLabel}>We are reviewing your documents</p>
      </div>
    );
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Take Driver's License Photo"
        subtitle="Plannery partners with Alloy to verify your identity. This should take 30 seconds."
      >
        <PartnerBanner name="Alloy" logo={<AlloyLogo />} />
        <div className={styles.descriptionContainer}>
          <div className={styles.descriptionItems}>
            {descriptionItems.map((item, index) => (
              <div className={styles.item} key={index}>
                <item.icon className={styles.icon} />
                <div className={styles.itemText}>{item.description}</div>
              </div>
            ))}
          </div>
        </div>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          renderAction()
        )}
      </FormContainer>
    </>
  );
};

export default Alloy;
