import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailApi } from 'api/EmailApi';

import { ErrorResponse } from './ErrorResponse';

export const createSendStudentLoanEnrollmentFormEmail = (api: EmailApi) =>
  createAsyncThunk('email/sendStudentLoanEnrollmentFormEmail', async (applicationId: string, thunkApi) => {
    try {
      await api.sendStudentLoanEnrollmentFormEmail(applicationId);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
