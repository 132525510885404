import React, { useState } from 'react';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import { ConversionType, trackConversion } from 'utils/analytics';
import { StudentLoanBookAppointmentResult } from 'enums/StudentLoanForgivenessFlowResults';
import CalendlyModal from 'components/StudentLoanForgiveness/Modal/CalendlyModal';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import TalkNowModal from 'components/StudentLoanForgiveness/Modal/TalkNowModal';
import { isBusinessHours } from 'utils/dateUtils';

import { ReactComponent as Contract } from 'images/contract.svg';

import styles from './BookAppointment.module.scss';

const BookAppointment = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const [isScheduleOpen, setScheduleIsOpen] = useState(false);
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [talkNowIsOpen, setTalkNowIsOpen] = useState(false);

  const handleSchedule = () => {
    analytics.track('Schedule Consultation Link Opened');
    setAreYouSureModalOpen(false);
    setScheduleIsOpen(true);
  };

  const onSchedule = () => {
    trackConversion(ConversionType.BookedStudentLoanMeeting);
    setScheduleIsOpen(false);
    handleNext(StudentLoanBookAppointmentResult.Schedule);
  };

  const handleTalkNow = () => {
    analytics.track('Schedule Consultation Talk Now Pressed');
    setTalkNowIsOpen(true);
  };

  const onCall = () => {
    analytics.track('Schedule Consultation Call Now Pressed');
    setTalkNowIsOpen(false);
  };

  const handleNotNow = () => {
    analytics.track('Schedule Consultation Not Now Pressed');
    handleNext(StudentLoanBookAppointmentResult.NotNow);
  };

  const isTalkNowAvailable = isBusinessHours(new Date(), 14, 22);

  return (
    <>
      <CalendlyModal open={isScheduleOpen} onClose={() => setScheduleIsOpen(false)} onSchedule={onSchedule} />
      {areYouSureModalOpen && (
        <AreYouSureModal
          onClose={() => setAreYouSureModalOpen(false)}
          onNext={handleSchedule}
          onNotNow={handleNotNow}
          nextLabel="Schedule Appointment"
        />
      )}
      <FormNavigation {...navigationInfo} />
      <FormContainer icon={<Contract />} title="Let's apply together!">
        <div className={styles.content}>
          <p className={styles.label}>
            Most healthcare workers who apply on their own get rejected. As a Plannery member, you get a one-time
            complimentary student loan review.
          </p>
          <p className={styles.label}>On the call you will:</p>
          <ol className={styles.benefits}>
            <li>See the specific federal, state, and local programs you qualify for.</li>
            <li>Discuss your savings and forgiveness eligibility.</li>
            <li>Optionally, our advisory team can enroll you in these programs and navigate the approval process.</li>
          </ol>
        </div>

        <div className={styles.buttonContainer}>
          <Button onClick={handleSchedule}>Check Availability</Button>

          {isTalkNowAvailable && (
            <Button type={ButtonType.Inverted} onClick={handleTalkNow}>
              Talk Now
            </Button>
          )}

          <Button type={ButtonType.Transparent} onClick={() => setAreYouSureModalOpen(true)}>
            Not Now
          </Button>
        </div>
      </FormContainer>

      {talkNowIsOpen && <TalkNowModal onClose={() => setTalkNowIsOpen(false)} onCall={onCall} />}
    </>
  );
};

export default BookAppointment;
