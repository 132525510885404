import { VerificationStepProgress } from 'components/Verification/verificationSteps';

import Api, { FetchMethodType } from './Api';
import { ApplyProgress } from './StudentLoanApi';

export enum DigifiDocumentType {
  Paystub = 'paystub',
  W2OrPaystub = 'w2OrPaystub',
  Other = 'other',
}

export interface DataForUploadingDocuments {
  applicationId: string;
  files: File[];
  fileType: DigifiDocumentType;
}

export interface UploadDocumentsResponse {
  total_documents_uploaded: number;
  verificationStepProgress: VerificationStepProgress;
  studentLoanApplyProgress: ApplyProgress;
}

export interface ApplicationDocumentsApi {
  uploadDocuments(data: DataForUploadingDocuments): Promise<UploadDocumentsResponse>;
}

export default class ApplicationDocumentsRestApi extends Api implements ApplicationDocumentsApi {
  public async uploadDocuments(data: DataForUploadingDocuments): Promise<UploadDocumentsResponse> {
    const formData = new FormData();

    data.files.map((file) => formData.append('files', file));

    formData.append('applicationId', data.applicationId as string);
    formData.append('fileType', data.fileType as string);

    const result = await this.fetch<UploadDocumentsResponse>(`/documents/upload`, {
      method: FetchMethodType.POST,
      body: formData,
      resetDefaultHeaders: true,
    });

    return result;
  }
}
