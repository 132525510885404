import { ApplicationDataApi, DataForUpdatingApplicationStatus } from 'api/ApplicationDataApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorResponse } from './ErrorResponse';

export const createApplicationStatusThunk = (api: ApplicationDataApi) =>
  createAsyncThunk(
    'applicationData/updateApplicationStatus',
    async (data: DataForUpdatingApplicationStatus, thunkApi) => {
      try {
        const applicationStatus = await api.updateApplicationStatus(data);

        return applicationStatus;
      } catch (error: unknown) {
        thunkApi.rejectWithValue((error as ErrorResponse).response.data);

        throw error;
      }
    },
  );
