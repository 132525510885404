import React from 'react';
import clsx from 'clsx';

import styles from './ComparisonTable.module.scss';

interface ComparisonTableProps {
  comparisonLabel: string;
  leftColumn: ComparisonColumn;
  rightColumn: ComparisonColumn;
}

interface ComparisonColumn {
  title: string;
  rows: ComparisonRow[];
}

interface ComparisonRow {
  amount: number | string;
  label: string;
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({ comparisonLabel, leftColumn, rightColumn: rightTable }) => (
  <div className={styles.container}>
    <span className={styles.comparisonLabel}>{comparisonLabel}</span>

    <div className={styles.tableContainer}>
      <div className={styles.tableColumn}>
        <p className={styles.title}>{leftColumn.title}</p>
        <div className={styles.columnBody}>
          {leftColumn.rows.map((row) => (
            <ComparisonTableRow {...row} key={row.label.trim()} />
          ))}
        </div>
      </div>
      <div className={clsx(styles.tableColumn, styles.highlighted)}>
        <p className={styles.title}>{rightTable.title}</p>
        <div className={styles.columnBody}>
          {rightTable.rows.map((row) => (
            <ComparisonTableRow {...row} key={row.label.trim()} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

const ComparisonTableRow: React.FC<ComparisonRow> = ({ amount, label }) => (
  <div>
    <p className={styles.amount}>{amount}</p>
    <p className={styles.label}>{label}</p>
  </div>
);

export default ComparisonTable;
