import React, { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { RoutePath } from 'enums/Routes';
import { UtmSource } from 'enums/UtmTagName';
import { useNavigate } from 'hooks/useNavigate';
import { updateApplicationFeedbackData } from 'thunks';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import BackLink from 'components/BackLink';
import { RootState } from 'handlers';
import FormContainer from 'components/LoanForm/FormContainer';

import styles from './Feedback.module.scss';

enum Fields {
  Experience = 'experience',
  HowDidYouHear = 'how_did_you_hear',
}

const Feedback = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const { application } = useSelector(getApplicationData);
  const utmSource = application?.utmSource;
  const experience = useSelector((state: RootState) => state.applicationData.application?.feedbackExperience);
  const howDidYouHear = useSelector((state: RootState) => state.applicationData.application?.feedbackHowDidYouHear);
  const [isLoading, setIsLoading] = useState(false);

  const { register, setValue, watch } = useForm({
    defaultValues: {
      [Fields.Experience]: experience,
      [Fields.HowDidYouHear]: howDidYouHear,
    },
  });

  const watcher = watch();

  useEffect(() => {
    register(Fields.Experience);
    register(Fields.HowDidYouHear);
  }, [register, watcher]);

  const handleExperienceOnChange = useCallback((event) => {
    setValue(Fields.Experience, event.target.value);
  }, []);

  const handleHowDidYouHearOnChange = useCallback((event) => {
    setValue(Fields.HowDidYouHear, event.target.value);
  }, []);

  const handleContinue = useCallback(async () => {
    setIsLoading(true);
    if (watcher[Fields.Experience] || watcher[Fields.HowDidYouHear]) {
      await dispatchWithUnwrap(
        updateApplicationFeedbackData({
          applicationId: application!.id,
          experience: watcher[Fields.Experience],
          howDidYouHear: watcher[Fields.HowDidYouHear],
        }),
      );
    }
    setIsLoading(false);
    navigate(RoutePath.NextSteps);
  }, [dispatchWithUnwrap, navigate, watcher, application]);

  return (
    <>
      <BackLink className={styles.backLink} />
      <FormContainer title="We’d love your feedback.">
        <TextArea
          label="How was your experience?"
          placeholder="Tell us anything on your mind."
          className={styles.formInput}
          name={Fields.Experience}
          onChange={handleExperienceOnChange}
          value={watcher[Fields.Experience]}
        />

        {(!utmSource || utmSource === UtmSource.Site) && (
          <>
            <TextArea
              label="How did you hear about us?"
              placeholder="Any details are welcome."
              className={styles.formInput}
              name={Fields.HowDidYouHear}
              onChange={handleHowDidYouHearOnChange}
              value={watcher[Fields.HowDidYouHear]}
            />
          </>
        )}

        <Button onClick={handleContinue} isLoading={isLoading}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default Feedback;
