import React, { ChangeEvent, FC, useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useScript } from 'components/ScriptsLoader/ScriptsLoader';
import { YourDetailsVariable } from 'enums/LoanFormVariables';
import { getParsedAddress } from 'utils/getParsedAddress';
import { useForm } from 'react-hook-form';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { STATE_OPTIONS } from 'utils/getCountryStateLabel';

import Input from 'components/Input';
import NumberInput from 'components/NumberInput';
import InputSelect from 'components/InputSelect';

import styles from './AddressFields.module.scss';

enum YourDetailsInputLabel {
  Address = 'Address',
  AddressLine1 = 'Address line 1',
  AddressLine2 = 'Address line 2 (Optional)',
  City = 'City',
  ZipCode = 'Zip code',
  State = 'State',
}

export interface Address {
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
}

export interface AddressProps {
  prefix?: string;
  value?: Address;
  onChange?: (value: Address, isValid: boolean) => void;
  disabled?: boolean;
  hideLine2?: boolean;
}

interface GeoCompletionResult {
  description: string;
}

export const isCompleteAddress = (address: Address): boolean =>
  Boolean(address.line1 && address.city && address.state && address.zip);

const AddressFields: FC<AddressProps> = ({ prefix, value, onChange: onChangeArg, disabled, hideLine2 }) => {
  const googleMapScript = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  );

  const {
    init,
    ready,
    suggestions: { status, data },
    setValue: setAutocompleteValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      componentRestrictions: { country: 'us' },
      types: ['address'],
    },
    debounce: 300,
  });

  useEffect(() => {
    if (googleMapScript.loaded) {
      init();
    }
  }, [googleMapScript.loaded]);

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleGooglePlacesInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue(YourDetailsVariable.AddressLine1, event.target.value);
    setAutocompleteValue(event.target.value);
    trigger(event.target.name as YourDetailsVariable);
  };

  const handleGooglePlacesSelect = (event: GeoCompletionResult) => async () => {
    const { description } = event;

    const [geocoderResult] = await getGeocode({ address: description });
    const address = getParsedAddress(geocoderResult.address_components);

    setValue(YourDetailsVariable.AddressLine1, address.address);
    setValue(YourDetailsVariable.ZipCode, address.zip);
    setValue(YourDetailsVariable.City, address.city);
    setValue(YourDetailsVariable.ArgyleState, address.state);

    trigger(YourDetailsVariable.AddressLine1 as YourDetailsVariable);
    trigger(YourDetailsVariable.ZipCode as YourDetailsVariable);
    trigger(YourDetailsVariable.City as YourDetailsVariable);
    trigger(YourDetailsVariable.ArgyleState as YourDetailsVariable);

    clearSuggestions();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
      } = suggestion;

      return (
        <li key={placeId} onClick={handleGooglePlacesSelect(suggestion)} className={styles.dropdownOption}>
          {mainText} {secondaryText}
        </li>
      );
    });

  const defaultValues = {
    [YourDetailsVariable.AddressLine1]: value?.line1,
    [YourDetailsVariable.AddressLine2]: value?.line2,
    [YourDetailsVariable.City]: value?.city,
    [YourDetailsVariable.ZipCode]: value?.zip,
    [YourDetailsVariable.ArgyleState]: value?.state,
  };

  const {
    register,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();

  useEffect(() => {
    register(YourDetailsVariable.AddressLine1, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.AddressLine1),
    });
    register(YourDetailsVariable.AddressLine2, {});
    register(YourDetailsVariable.City, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.City),
    });
    register(YourDetailsVariable.ZipCode, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.ZipCode),
      pattern: {
        message: getMessageForInvalidFields(YourDetailsInputLabel.ZipCode),
        value: /^\d{5}$/,
      },
    });
    register(YourDetailsVariable.ArgyleState, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.State),
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourDetailsVariable, event.target.value.trim());
    trigger(event.target.name as YourDetailsVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourDetailsVariable, event.target.value);
    trigger(event.target.name as YourDetailsVariable);
  };

  const callOnChange = () => {
    onChangeArg?.(
      {
        line1: watcher[YourDetailsVariable.AddressLine1],
        line2: watcher[YourDetailsVariable.AddressLine2],
        city: watcher[YourDetailsVariable.City],
        state: watcher[YourDetailsVariable.ArgyleState],
        zip: watcher[YourDetailsVariable.ZipCode],
      },
      isValid,
    );
  };

  useEffect(callOnChange, [
    isValid,
    watcher[YourDetailsVariable.AddressLine1],
    watcher[YourDetailsVariable.AddressLine2],
    watcher[YourDetailsVariable.City],
    watcher[YourDetailsVariable.ArgyleState],
    watcher[YourDetailsVariable.ZipCode],
  ]);

  const maybePrefix = (label: string) => (prefix ? `${prefix} ${label.toLowerCase()}` : label);

  return (
    <>
      <div className={styles.dropdownContainer} ref={ref}>
        <Input
          label={maybePrefix(hideLine2 ? YourDetailsInputLabel.Address : YourDetailsInputLabel.AddressLine1)}
          placeholder="Address Line 1"
          errorMessage={errors[YourDetailsVariable.AddressLine1]?.message}
          name={YourDetailsVariable.AddressLine1}
          onBlur={onBlur}
          value={watcher[YourDetailsVariable.AddressLine1]}
          onChange={handleGooglePlacesInput}
          disabled={(!googleMapScript.loaded && !ready) || disabled}
        />
        {status === 'OK' && <ul className={styles.dropdownOptionsList}>{renderSuggestions()}</ul>}
      </div>

      {hideLine2 !== true && (
        <Input
          label={maybePrefix(YourDetailsInputLabel.AddressLine2)}
          placeholder="Address Line 2"
          name={YourDetailsVariable.AddressLine2}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[YourDetailsVariable.AddressLine2]}
          disabled={(!googleMapScript.loaded && !ready) || disabled}
        />
      )}

      <Input
        label={maybePrefix(YourDetailsInputLabel.City)}
        placeholder="City"
        errorMessage={errors[YourDetailsVariable.City]?.message}
        name={YourDetailsVariable.City}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[YourDetailsVariable.City]}
        disabled={disabled}
      />

      <InputSelect
        label={maybePrefix(YourDetailsInputLabel.State)}
        options={STATE_OPTIONS}
        onChange={async (option) => {
          setValue(YourDetailsVariable.ArgyleState, option.value);
          trigger(YourDetailsVariable.ArgyleState as YourDetailsVariable);
          // TODO: Figure out why the watcher doesn't update without this.
          watcher[YourDetailsVariable.ArgyleState] = option.value;
        }}
        value={watcher[YourDetailsVariable.ArgyleState]}
        placeholder="State"
        disabled={disabled}
        name={YourDetailsVariable.ArgyleState}
      />

      <NumberInput
        label={maybePrefix(YourDetailsInputLabel.ZipCode)}
        placeholder="Zip code"
        errorMessage={errors[YourDetailsVariable.ZipCode]?.message}
        name={YourDetailsVariable.ZipCode}
        onChange={onChange}
        value={watcher[YourDetailsVariable.ZipCode]}
        disabled={disabled}
      />
    </>
  );
};

export default AddressFields;
