import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { previewEcf } from 'thunks';

import Button, { ButtonType } from 'components/Button/Button';
import LinkButton from 'components/LinkButton';
import Loader from 'components/Loader';

import { Document, Page, pdfjs } from 'react-pdf';

import styles from './SignatureCollector.module.scss';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.js', import.meta.url).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface Props {
  applicationId: string;
  onLoadSuccess?: () => void;
  onLoadError?: () => void;
}

export default function EcfFormPreview({ applicationId, onLoadSuccess, onLoadError }: Props) {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [loading, setLoading] = useState(true);

  const [pdfData, setPdfData] = useState<string>();

  const [fullscreen, setFullscreen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    setLoading(true);
    dispatchWithUnwrap(previewEcf(applicationId))
      .then((data) => {
        setPdfData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#9d86f9" size={50} />
      </div>
    );
  }

  // <embed src={pdfData} type="application/pdf" width="80%" height="400" />

  let width: number | undefined;
  let showFullscreenToggle: boolean = true;
  if (fullscreen) {
    width = window.innerWidth;
  } else if (window.innerWidth < 768) {
    width = window.innerWidth * 0.9;
    showFullscreenToggle = false;
  }

  return (
    <div className={clsx({ [styles.modal]: fullscreen })}>
      <Document
        file={pdfData}
        loading=""
        error=""
        onLoadSuccess={(e) => {
          setPageCount(e.numPages);
          onLoadSuccess?.();
        }}
        onLoadError={() => {
          onLoadError?.();
        }}
      >
        <div className={styles.controlsContainer}>
          {showFullscreenToggle && (
            <Button
              type={ButtonType.Transparent}
              onClick={() => setFullscreen(!fullscreen)}
              className={styles.fullscreenButton}
            >
              {fullscreen ? 'Exit' : 'View'} Full Screen
            </Button>
          )}

          {!fullscreen && (
            <LinkButton onClick={() => setPage(page - 1)} disabled={page === 1} className={styles.prevNext}>
              Previous
            </LinkButton>
          )}
          {!fullscreen && (
            <LinkButton onClick={() => setPage(page + 1)} disabled={page === pageCount} className={styles.prevNext}>
              Next
            </LinkButton>
          )}
        </div>

        <Page width={width} pageNumber={page} renderAnnotationLayer={false} renderTextLayer={false} />
      </Document>
    </div>
  );
}
