import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

import { ErrorResponse } from './ErrorResponse';

export interface UploadPaystubsData {
  applicationId: string;
  files: File[];
}

export const createUploadPaystubsThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/uploadPaystubs', async (data: UploadPaystubsData, thunkApi) => {
    try {
      return await api.uploadPaystubs(data.applicationId, data.files);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
