import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingAdditionalFunds } from 'api/ApplicationDataApi';

export const createUpdateAdditionalFundsThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/updateAdditionalFunds', async (data: DataForUpdatingAdditionalFunds, thunkApi) => {
    try {
      return await api.updateAdditionalFunds(data.applicationId, data.additionalFundsAmount);
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
