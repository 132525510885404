import Api, { FetchMethodType } from './Api';

export interface LocationData {
  clientCountryRegion: string;
  clientCity: string;
}

export interface PreQualificationApi {
  getUserLocation(): Promise<LocationData>;
}

export default class PreQualificationRestApi extends Api implements PreQualificationApi {
  public async getUserLocation() {
    const data = await this.fetch<LocationData>(`/prequalification/location`, {
      method: FetchMethodType.GET,
    });

    return data;
  }
}
