import React from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { FlowComponentType } from 'routes/types';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { addDays, formatTextDate } from 'utils/dateUtils';
import Contact from 'components/Contact';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { DEFAULT_LATE_FEE, LATE_FEE_DAYS } from 'components/MissedPayments/constants';

import styles from './PaymentFailed.module.scss';

const PaymentFailed = ({ navigationInfo }: FlowComponentType) => {
  const navigate = useNavigate();
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { missedPaymentAmount, missedPaymentDate, lateFee, bankName } = application;

  const date = new Date(missedPaymentDate!);

  return (
    <div>
      <FormNavigation {...navigationInfo} onBackClick={() => navigate(RoutePath.MakeAPayment)} />
      <FormContainer className={styles.container} title="That’s a bummer">
        <p className={styles.text}>
          You don’t have enough funds in your {bankName} account to make this payment. You need at least{' '}
          {formatMonetaryAmount(missedPaymentAmount, true)}.
        </p>
        <h2 className={styles.title}>Please contact us right away to avoid a late fee.</h2>
        <p className={styles.text}>
          A {formatMonetaryAmount(lateFee || DEFAULT_LATE_FEE)} late fee will be charged on{' '}
          {formatTextDate(addDays(date, LATE_FEE_DAYS))}.
        </p>
      </FormContainer>

      <Contact className={styles.contact} showBackButton={false} />
    </div>
  );
};

export default PaymentFailed;
