import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import { FlowComponentType } from 'routes/types';
import Button from 'components/Button';
import { StudentLoanSaveRepaymentPlanResult as SaveAwarenessResult } from 'enums/StudentLoanForgivenessFlowResults';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const options = [
  { id: SaveAwarenessResult.Enrolled, label: "Yes; I'm enrolled in this" },
  { id: SaveAwarenessResult.NotEnrolled, label: "Yes, but I haven't enrolled" },
  { id: SaveAwarenessResult.HaveNotHeard, label: 'No' },
];

const SaveAwareness = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const [selected, setSelected] = useState<SaveAwarenessResult | null>(null);

  const handleNext = () => {
    analytics.track('Student Loan SAVE Repayment Plan Selected', { selected });
    onNext(selected!);
  };
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <RadioButtonList
            label="Are you familiar with the SAVE repayment plan?"
            selected={selected}
            setSelected={setSelected}
            options={options}
          />

          <Button disabled={Boolean(!selected)} onClick={handleNext} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default SaveAwareness;
