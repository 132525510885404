import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApplicationData, joinDebtConsolidationWaitlist } from 'thunks';

import { GetApplicationDataResponse } from './applicationData';

interface DebtConsolidationState {
  joined: boolean;
  isLoading: boolean;
  error: boolean;
}

const initialState: DebtConsolidationState = {
  joined: false,
  isLoading: false,
  error: false,
};

const debtConsolidation = createSlice({
  name: 'debtConsolidation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(joinDebtConsolidationWaitlist.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(joinDebtConsolidationWaitlist.fulfilled, (state) => {
      state.isLoading = false;
      state.joined = true;
    });
    builder.addCase(joinDebtConsolidationWaitlist.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.joined = payload.application.onDebtConsolidationWaitlist ?? false;
    });
  },
});

export default debtConsolidation.reducer;
