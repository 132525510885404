import React, { useEffect } from 'react';

import { FlowComponentType } from 'routes/types';
import { useApplySteps } from 'components/StudentLoanForgiveness/Apply/applySteps';
import { StepsResult } from 'enums/FlowNextResults';
import { ApplyStep } from 'api/StudentLoanApi';

const ServiceAgreementDone = ({ handleNext }: FlowComponentType): JSX.Element => {
  const { currentStep, handleNextStep, isLastStep } = useApplySteps();

  useEffect(() => {
    if (currentStep === ApplyStep.SignServiceAgreement) {
      handleNextStep(StepsResult.Completed);
      handleNext(isLastStep ? StepsResult.Completed : undefined);
    } else {
      handleNext();
    }
  });

  return <></>;
};

export default ServiceAgreementDone;
