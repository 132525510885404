import { useSelector } from 'react-redux';

import { ReactComponent as BankAccount } from 'images/step-icons/bank-account-solid.svg';
import { ReactComponent as BriefCase } from 'images/step-icons/brief-case-solid.svg';
import { ReactComponent as License } from 'images/step-icons/license-solid.svg';
import { ReactComponent as Paystubs } from 'images/step-icons/paystubs-solid.svg';
import EmploymentDetailsStep from 'components/Verification/Steps/EmploymentDetails/EmploymentDetails';
import VerifyIdentityStep from 'components/Verification/Steps/VerifyIdentity';
import UploadPaystubsStep from 'components/Verification/Steps/UploadPaystubs/UploadPaystubs';
import CheckingAccountStep from 'components/Verification/Steps/CheckingAccount/Plaid/Plaid';
import { getApplicationData } from 'selectors/getApplicationData';
import { Employer, completeVerificationStep, setCurrentVerificationStep } from 'handlers/applicationData';
import { FlowComponentType } from 'routes/types';
import { StepsNavigation } from 'components/Steps/StepsNavigation/StepsNavigation';
import { useSteps, StepData } from 'components/Steps/stepUtils';
import FirstTimeFlow from 'components/Steps/StepsFlow/FirstTimeFlow';
import ReturningFlow from 'components/Steps/StepsFlow/ReturningFlow';
import { RoutePath } from 'enums/Routes';

export enum VerificationStep {
  EmploymentDetails = 'employmentDetails',
  UploadPaystubs = 'paystubs',
  CheckingAccount = 'bankAccount',
  VerifyIdentity = 'kyc',
}

export type VerificationStepProgress = {
  employmentDetails: boolean;
  paystubs: boolean;
  bankAccount: boolean;
  kyc: boolean;
};

export const defaultVerificationProgress: Readonly<VerificationStepProgress> = Object.freeze({
  [VerificationStep.EmploymentDetails]: false,
  [VerificationStep.UploadPaystubs]: false,
  [VerificationStep.CheckingAccount]: false,
  [VerificationStep.VerifyIdentity]: false,
});

const isRelevantStep = (step: VerificationStep, verifiedEmployer: boolean): boolean => {
  if (verifiedEmployer) {
    return step !== VerificationStep.UploadPaystubs;
  }
  return true;
};

export const verificationSteps: Readonly<StepData<VerificationStep>[]> = [
  {
    id: VerificationStep.EmploymentDetails,
    name: 'Employment Details',
    component: EmploymentDetailsStep,
    icon: BriefCase,
  },
  {
    id: VerificationStep.UploadPaystubs,
    name: 'Upload Paystubs',
    component: UploadPaystubsStep,
    icon: Paystubs,
  },
  {
    id: VerificationStep.CheckingAccount,
    name: 'Connect Checking Account',
    component: CheckingAccountStep,
    icon: BankAccount,
  },
  {
    id: VerificationStep.VerifyIdentity,
    name: 'Verify Identity',
    component: VerifyIdentityStep,
    icon: License,
  },
] as const;

export const useVerificationSteps = () => {
  const { application } = useSelector(getApplicationData);

  const verificationStepsStatus = application?.verificationStepProgress ?? defaultVerificationProgress;
  const { verifiedName, verifiedAnnualIncome, verifiedHireDate } =
    application?.employment.employer1 ?? ({} as Employer);
  const verifiedEmployer = verifiedName === true && verifiedAnnualIncome === true && verifiedHireDate === true;

  const steps = verificationSteps.filter((step) => isRelevantStep(step.id, verifiedEmployer));

  return useSteps(
    useSelector(getApplicationData).currentVerificationStep ?? verificationSteps[0].id,
    setCurrentVerificationStep,
    completeVerificationStep,
    steps,
    verificationStepsStatus,
    RoutePath.VerifyingApplication,
  );
};

export const VerificationNavigation = (args: FlowComponentType) =>
  StepsNavigation<VerificationStep, VerificationStepProgress>(args, useVerificationSteps);
export const VerificationFirstTimeFlow = () =>
  FirstTimeFlow<VerificationStep, VerificationStepProgress>(useVerificationSteps);
export const VerificationReturningFlow = () =>
  ReturningFlow<VerificationStep, VerificationStepProgress>(useVerificationSteps, RoutePath.VerificationSteps);
