import { createAsyncThunk } from '@reduxjs/toolkit';

import { StudentLoanApi } from 'api/StudentLoanApi';

import { ErrorResponse } from './ErrorResponse';

export interface SignEcfArgs {
  applicationId: string;
  signatureDataUrlPng: string;
}

export const createSignEcfThunk = (api: StudentLoanApi) =>
  createAsyncThunk('documents/signEcf', async (args: SignEcfArgs, thunkApi) => {
    const { applicationId, signatureDataUrlPng } = args;
    try {
      return await api.signEnrollmentForm(applicationId, signatureDataUrlPng);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
