import React from 'react';
import clsx from 'clsx';

import { ReactComponent as PlusIcon } from 'images/plus-circle-icon.svg';
import { ReactComponent as MinusIcon } from 'images/minus-circle-icon.svg';

import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import Loader from 'components/Loader';

import styles from './NumericStepper.module.scss';

export enum NumericStepperFormat {
  Currency,
  Number,
}

interface NumericStepperProps {
  isLoading?: boolean;
  options?: {
    format?: NumericStepperFormat;
  };
  maxValue: number;
  minValue: number;
  setValue: (value: number) => void;
  value: number;
}

const getNumberFormated = (value: number, format?: NumericStepperFormat) => {
  switch (format) {
    case NumericStepperFormat.Currency:
      return formatMonetaryAmount(value);
    case NumericStepperFormat.Number:
      return value.toString();
    default:
      return value.toString();
  }
};

const calculateStep = (value: number, increment: boolean, minValue: number, maxValue: number) => {
  // Step value depending on the range
  let stepValue = 100;
  if (maxValue - minValue > 1000) stepValue = 500;
  if (maxValue - minValue > 5000) stepValue = 1000;

  let newValue;
  if (value % stepValue === 0) {
    newValue = value + (increment ? 1 : -1) * stepValue;
  } else if (increment) {
    newValue = value - (value % stepValue) + stepValue;
  } else {
    newValue = value - (value % stepValue);
  }

  return Math.max(Math.min(newValue, maxValue), minValue);
};

const NumericStepper = ({ isLoading, value, maxValue, minValue, setValue, options }: NumericStepperProps) => (
  <div className={styles.container}>
    <div className={styles.number}>{getNumberFormated(value, options?.format)}</div>
    {isLoading ? (
      <div className={styles.loaderContainer}>
        <Loader color="#9d86f9" size={24} />
      </div>
    ) : (
      <div className={styles.stepper}>
        <div
          className={clsx(styles.button, styles.minus, { [styles.inactive]: value <= minValue })}
          onClick={() => setValue(calculateStep(value, false, minValue, maxValue))}
        >
          <MinusIcon />
        </div>
        <div
          className={clsx(styles.button, styles.plus, { [styles.inactive]: value >= maxValue })}
          onClick={() => setValue(calculateStep(value, true, minValue, maxValue))}
        >
          <PlusIcon />
        </div>
      </div>
    )}
  </div>
);

export default NumericStepper;
