import React from 'react';

import { ReactComponent as RoadSign } from 'images/road-sign.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';

import FormContainer from 'components/LoanForm/FormContainer';

const ErrorConsolidating = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <FormContainer
      title="Sorry, we’re unable to assist you with your personal debt at this time."
      subtitle="We emailed you with details. Let’s explore other ways we can save you money."
      icon={<RoadSign />}
    >
      <Button type={ButtonType.Primary} onClick={handleNext}>
        Continue
      </Button>
    </FormContainer>
  </>
);

export default ErrorConsolidating;
