import React from 'react';

import { ReactComponent as StudentLoan } from 'images/student-loan.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';

import styles from './CheckForgiveness.module.scss';

const CheckForgiveness = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <FormContainer
      title="Check Forgiveness Status"
      icon={<StudentLoan />}
      subtitle="Please follow the steps to check your status."
    >
      <ol className={styles.steps}>
        <li>
          Log in to{' '}
          <a href="https://studentaid.gov/fsa-id/sign-in" target="_blank" rel="noreferrer">
            studentaid.gov
          </a>
          .
        </li>
        <li>
          From the dashboard, click the <b>View Details</b> button.{getImage(1)}
        </li>
        <li>
          Click <b>View Breakdown</b> next to Loans.{getImage(2)}
        </li>
        <li>
          The page will scroll down. Click <b>View Loan Details</b>.{getImage(3)}
        </li>
        <li>
          Your loans will expand. For each loan you want to check, click <b>View Loan Details</b>.{getImage(4)}
        </li>
        <li>
          If you see <b>PSLF Cumulative Match Months</b>, you're enrolled in PSLF. You can get these loans forgiven when
          this number reaches 120.{getImage(5)}
        </li>
      </ol>
      <Button type={ButtonType.Primary} onClick={handleNext}>
        Continue
      </Button>
    </FormContainer>
  </>
);

const getImage = (stepNumber: number) => (
  <img
    src={`https://assets.planneryapp.com/media/student-loan-assets/check-forgiveness-${stepNumber}.png`}
    alt="Example"
    width="100%"
  />
);

export default CheckForgiveness;
