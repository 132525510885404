import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import GraphCard from 'components/GraphCard/GraphCard';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { FlowComponentType } from 'routes/types';
import { HardOfferData, LoanType } from 'handlers/applicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as CreditCardIcon } from 'images/credit-card-icon.svg';
import { ReactComponent as LoanIcon } from 'images/loan-icon.svg';
import { ReactComponent as BorrowIcon } from 'images/borrow-icon.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-icon.svg';
import { ReactComponent as MoneyCoinsIcon } from 'images/money-coins-icon.svg';
import { ReactComponent as GraphArrowConsolidate } from 'images/arrow-consolidate.svg';
import { ReactComponent as GraphArrowSingle } from 'images/arrow-single.svg';
import { ReactComponent as GraphArrowDouble } from 'images/arrow-double.svg';
import { ReactComponent as GraphArrowTriple } from 'images/arrow-triple.svg';
import { ConversionType, trackConversion } from 'utils/analytics';
import { ButtonType } from 'components/Button/Button';
import { HowItWorksResult } from 'enums/FlowNextResults';

import styles from './HowItWorks.module.scss';

enum Debt {
  CARD = 'cards',
  LOAN = 'loans',
  BORROW = 'borrow',
}

const HowItWorks = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const { additionalFundsTotal, payOff, keepIt, planneryLoan } = (useSelector(getApplicationData).application
    ?.hardOffer ?? {}) as HardOfferData;
  const { applied: cardApplied } = useSelector(getCardData);

  // A customer could have pick some keep debts to consolidate
  const tradelines = [...(payOff ?? []), ...(keepIt ?? [])];
  const cardDebt = tradelines.filter((debt) => debt.loanType === LoanType.CreditCard);
  const loanDebt = tradelines.filter((debt) => debt.loanType !== LoanType.CreditCard);
  const additionalFunds = additionalFundsTotal?.amount ?? 0;

  const cardBalance = cardDebt
    .filter((debt) => debt.selectedForConsolidation)
    .reduce((acc, debt) => acc + debt.balanceToConsolidate ?? 0, 0);
  const loanBalance = loanDebt
    .filter((debt) => debt.selectedForConsolidation)
    .reduce((acc, debt) => acc + debt.balanceToConsolidate ?? 0, 0);

  const debts: Debt[] = [];
  if (cardBalance > 0) debts.push(Debt.CARD);
  if (loanBalance > 0) debts.push(Debt.LOAN);
  if (additionalFunds > 0) debts.push(Debt.BORROW);

  // Debt Texts
  const cardText =
    cardBalance > 0 ? (
      <span className={styles.creditCardsText}>
        {debts.length === 1 ? 'Your Credit ' : ''}Cards: <b>{formatMonetaryAmount(cardBalance)}</b>
      </span>
    ) : undefined;
  const loanText =
    loanBalance > 0 ? (
      <span className={styles.personalLoanText}>
        {debts.length === 1 ? 'Your ' : ''}Loans: <b>{formatMonetaryAmount(loanBalance)}</b>
      </span>
    ) : undefined;
  const borrowText =
    additionalFunds > 0 ? (
      // If its only additional funds we skip this screen
      <span className={styles.borrowText}>
        Borrow: <b>{formatMonetaryAmount(additionalFunds)}</b>
      </span>
    ) : undefined;

  const getDebtArrow = () => {
    switch (debts.length) {
      case 1:
        return <GraphArrowSingle className={styles.arrow} />;
      case 2:
        return <GraphArrowDouble className={clsx(styles.arrowDouble, styles[debts.join('')])} />;
      default:
        return <GraphArrowTriple className={styles.arrowTriple} />;
    }
  };

  const viewOffer = () => {
    trackConversion(ConversionType.LoanAppliedAndQualified);
    handleNext(HowItWorksResult.Continue);
  };

  const getConsolidateText = () => {
    const debtsToDisplay: string[] = debts.filter((debt) => [Debt.CARD, Debt.LOAN].includes(debt));
    return (
      <span>
        Your Consolidated Debt = <b>{formatMonetaryAmount(planneryLoan?.amount)}</b>
        <br />
        We pay off your {debtsToDisplay.join(' and ')}
      </span>
    );
  };

  const loanParts: { icon: React.FunctionComponent; className: string; text: JSX.Element }[] = [];
  if (loanText) {
    loanParts.push({
      icon: LoanIcon,
      className: styles.loan,
      text: loanText,
    });
  }
  if (cardText) {
    loanParts.push({
      icon: CreditCardIcon,
      className: styles.card,
      text: cardText,
    });
  }
  if (borrowText) {
    loanParts.push({
      icon: BorrowIcon,
      className: styles.borrow,
      text: borrowText,
    });
  }

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="How it Works" className={styles.formContainer}>
        <div className={styles.diagram}>
          <div
            className={clsx({
              [styles.row]: debts.length > 1,
            })}
          >
            {loanParts.map((item) => (
              <GraphCard className={clsx(styles.loanPart, item.className)} icon={item.icon}>
                {item.text}
              </GraphCard>
            ))}
          </div>
          {getDebtArrow()}
          <GraphCard className={styles.consolidate} icon={MoneyCoinsIcon}>
            {getConsolidateText()}
          </GraphCard>
          <GraphArrowConsolidate className={styles.arrow} />
          <GraphCard className={styles.term} icon={CalendarIcon}>
            <span>
              You pay us one simple monthly payment. Eliminate this debt in <b>{planneryLoan?.term} months</b>
            </span>
          </GraphCard>
        </div>
      </FormContainer>
      <div className={styles.container}>
        <Button onClick={viewOffer}>View and customize offer</Button>
        {cardApplied && (
          <Button
            type={ButtonType.Inverted}
            className={styles.buttonMarginTop}
            onClick={() => {
              handleNext(HowItWorksResult.BackToYourFinances);
            }}
          >
            Not Now
          </Button>
        )}
      </div>
    </>
  );
};

export default HowItWorks;
