import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YourSSN4Variable } from 'enums/LoanFormVariables';

export interface SSN4Interface {
  [YourSSN4Variable.SSN4]: string;
}

export const initialState: SSN4Interface = {
  [YourSSN4Variable.SSN4]: '',
};

const yourSSN4 = createSlice({
  name: 'yourSSN4',
  initialState,
  reducers: {
    setYourSSN4: (state: SSN4Interface, { payload }: PayloadAction<SSN4Interface>) => {
      state[YourSSN4Variable.SSN4] = payload[YourSSN4Variable.SSN4];
    },
  },
});

export const { setYourSSN4 } = yourSSN4.actions;

export default yourSSN4.reducer;
