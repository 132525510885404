import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { FlowComponentType } from 'routes/types';
import StateContainer from 'components/StateContainer';
import FormContainer from 'components/LoanForm/FormContainer';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { makeACHPayment } from 'thunks';
import { RoutePath } from 'enums/Routes';
import { useNavigate, useNavigationType } from 'hooks/useNavigate';
import { MakingPaymentResult } from 'enums/FlowNextResults';

const MakingPayment = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigationType = useNavigationType();
  const navigate = useNavigate();

  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  useEffect(() => {
    const makePayment = async () => {
      const result = await dispatchWithUnwrap(makeACHPayment({ applicationId: application.id }));
      if (result.success) {
        handleNext(MakingPaymentResult.Success);
      } else {
        handleNext(MakingPaymentResult.Failure);
      }
    };

    // if we are navigating back to this page, don't make the payment again
    if (navigationType === 'POP') {
      navigate(RoutePath.MakeAPayment);
    }
    makePayment();
  }, []);

  return (
    <div>
      <FormContainer title="">
        <StateContainer icon={<LoadingSpinner />} title="Making Payment..." />
      </FormContainer>
    </div>
  );
};

export default MakingPayment;
