import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import { StepComponent } from 'components/Steps/stepUtils';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { StepsResult } from 'enums/FlowNextResults';
import { ReactComponent as InboxImage } from 'images/inbox.svg';
import { getStudentLoanApplication as getStudentLoanApplicationThunk, signEcf } from 'thunks';
import SignatureCollector from 'components/StudentLoanSignatureCollector';

import styles from './SignEnrollmentForm.module.scss';

const SignEnrollmentForm = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { applicationId } = useSelector(getStudentLoanApplication);

  // The following code ensures that the application is loaded before advancing to the next step.
  useEffect(() => {
    applicationId &&
      (async () => {
        await dispatchWithUnwrap(getStudentLoanApplicationThunk({ applicationId }));
      })();
  }, []);

  const onSign = async (signatureImage: string) => {
    analytics.track('Upload Ennrollment Form in Progress');

    // await dispatchWithUnwrap(
    //   uploadEnrollmentForm({
    //     applicationId: applicationId!,
    //     file: filesForUpload[0],
    //   }),
    // );

    await dispatchWithUnwrap(
      signEcf({
        signatureDataUrlPng: signatureImage,
        applicationId: applicationId!,
      }),
    );

    handleNext(StepsResult.Completed);
  };

  return (
    <FormContainer
      title="Sign Enrollment Form"
      subtitle="Please review and sign the form below."
      icon={<InboxImage />}
      className={styles.formContainer}
    >
      <SignatureCollector onSubmit={onSign} />
    </FormContainer>
  );
};

export default SignEnrollmentForm;
