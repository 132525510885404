import React from 'react';

import Button from 'components/Button';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import { FlowComponentType } from 'routes/types';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { ButtonType } from 'components/Button/Button';

const ApplyStepsCompleted = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => (
  <>
    <FormNavigation {...navigationInfo} showBackLink={false} />
    <FormContainer
      icon={<CheckIcon />}
      title="Application Completed"
      subtitle="Thank you. We'll contact you with updates on your application."
    >
      <Button onClick={handleNext}>Done</Button>
      <Button
        type={ButtonType.Inverted}
        onClick={() => {
          window.history.back();
        }}
      >
        Edit Application
      </Button>
    </FormContainer>
  </>
);

export default ApplyStepsCompleted;
