import React, { useEffect } from 'react';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { YourSSN4Variable } from 'enums/LoanFormVariables';
import FormContainer from 'components/LoanForm/FormContainer';
import { setYourSSN4 } from 'handlers/yourSSN4';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import Input from 'components/Input';

const SOCIAL_SECURITY_LENGTH_PATTERN = /^.{4}$/;

export enum YourSSN4InputLabel {
  SSN4 = 'Last 4 digits of your Social Security number',
}

const YourSSN = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatch = useDispatch();
  const { SSN4 } = useSelector((state: RootState) => state.yourSSN4);

  const {
    formState: { errors, isValid },
    register,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [YourSSN4Variable.SSN4]: SSN4,
    },
  });

  const watcher = watch();

  useEffect(() => {
    register(YourSSN4Variable.SSN4, {
      required: getMessageForRequiredFields(YourSSN4InputLabel.SSN4),
      pattern: {
        message: getMessageForInvalidFields(YourSSN4InputLabel.SSN4),
        value: SOCIAL_SECURITY_LENGTH_PATTERN,
      },
    });
  });

  const handleContinue = () => {
    dispatch(
      setYourSSN4({
        [YourSSN4Variable.SSN4]: watcher[YourSSN4Variable.SSN4],
      }),
    );
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Confirm Last 4 of Your SSN" subtitle="We need this to pull your data.">
        <Input
          label={YourSSN4InputLabel.SSN4}
          placeholder="XXXX"
          errorMessage={errors[YourSSN4Variable.SSN4]?.message}
          name={YourSSN4InputLabel.SSN4}
          onChange={(event: React.FocusEvent<HTMLInputElement>) => {
            setValue(YourSSN4Variable.SSN4, event.target.value.trim());
            trigger(YourSSN4Variable.SSN4);
          }}
          value={watcher[YourSSN4Variable.SSN4]}
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          type="number"
          autoFocus
        />

        <Button onClick={handleContinue} disabled={!isValid || watcher[YourSSN4Variable.SSN4].length !== 4}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default YourSSN;
