import { PaymentApi, SetupStudentLoanPaymentData } from 'api/PaymentApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorResponse } from './ErrorResponse';

export const createSetupStudentLoanPayment = (api: PaymentApi) =>
  createAsyncThunk('payment/studentLoanAssitance/setup', async (data: SetupStudentLoanPaymentData, thunkApi) => {
    try {
      return await api.setupStudentLoanPayment(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
