import { createAsyncThunk } from '@reduxjs/toolkit';
import { CardApi } from 'api/CardApi';

import { ErrorResponse } from './ErrorResponse';

export const createGetCardDataForLoanApplication = (api: CardApi) =>
  createAsyncThunk('card/loanApplication', async (appId: string, thunkApi) => {
    try {
      return await api.getCardDataForLoanApplication(appId);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);
      throw error;
    }
  });
