import React, { useState } from 'react';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { ReactComponent as CalendarLogo } from 'images/calendar.svg';
import { FlowComponentType } from 'routes/types';
import { StudentLoanBookAdvisorResult } from 'enums/StudentLoanForgivenessFlowResults';
import { ConversionType, trackConversion } from 'utils/analytics';
import CalendlyModal from 'components/StudentLoanForgiveness/Modal/CalendlyModal';

import { ReactComponent as ProfileIcon } from 'images/profile.svg';
import { ReactComponent as PlanIcon } from 'images/plan-icon.svg';

import styles from './BookAdvisor.module.scss';

function BookAdvisor({ navigationInfo, handleNext }: FlowComponentType) {
  const [isScheduleOpen, setScheduleIsOpen] = useState(false);

  const handleSchedule = () => {
    analytics.track('Schedule Consultation Link Opened');
    setScheduleIsOpen(true);
  };

  const onSchedule = () => {
    trackConversion(ConversionType.BookedStudentLoanMeeting);
    setScheduleIsOpen(false);
    handleNext(StudentLoanBookAdvisorResult.Schedule);
  };

  return (
    <>
      <CalendlyModal open={isScheduleOpen} onClose={() => setScheduleIsOpen(false)} onSchedule={onSchedule} />
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<CalendarLogo />}
        title="We're here to help!"
        subtitle="As a Plannery member, you get a free 30 minute conversation with a student loan advisor."
      >
        <div className={styles.advantages}>
          <div className={styles.advantage}>
            <ProfileIcon className={styles.icon} />
            <p className={styles.advantageLabel}>
              Talk to an expert who will help you learn which of the dozens of programs you may benefit from.
            </p>
          </div>
          <div className={styles.advantage}>
            <PlanIcon className={styles.icon} />
            <p className={styles.advantageLabel}>
              We can apply on your behalf, appeal if you get rejected, and file annual certifications.
            </p>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button onClick={handleSchedule}>Schedule Conversation</Button>

          <Button type={ButtonType.Inverted} onClick={() => handleNext(StudentLoanBookAdvisorResult.NotNow)}>
            Not Now
          </Button>
        </div>
      </FormContainer>
    </>
  );
}

export default BookAdvisor;
