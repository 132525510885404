import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { RootState } from 'handlers';
import { uploadStudentAidData } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import DropzoneWidget, { useFiles } from 'components/DropzoneWidget';
import { getApplicationData } from 'selectors/getApplicationData';
import { StepComponent } from 'components/Steps/stepUtils';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { StepsResult } from 'enums/FlowNextResults';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';

import styles from './UploadLoanInformation.module.scss';

const FilesPreview = React.lazy(() => import('components/FilesPreview'));

const renameFiles = (cb: (files: File[]) => void, filePrefix?: string) => (files: File[]) => {
  const prefix = filePrefix ? `${filePrefix} ` : '';
  return cb(files.map((file) => new File([file], `${prefix}${file.name}`, { type: file.type })));
};

const UploadLoanInformation = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { handleAddFiles, files, handleRemoveFiles } = useFiles();

  const {
    applicationId,
    applicationData: { applyProgress },
  } = useSelector(getStudentLoanApplication);

  const { isApplicationStatusUpdating } = useSelector(getApplicationData);
  const { isLoading } = useSelector((state: RootState) => state.filesUpload);

  const handleContinue = async () => {
    const filesForUpload: File[] = files.map((item) => item.file);

    analytics.track('Upload Loan Information in Progress');
    await dispatchWithUnwrap(
      uploadStudentAidData({
        applicationId: applicationId!,
        file: filesForUpload[0],
      }),
    );

    handleNext(StepsResult.Completed);
  };

  return (
    <FormContainer
      title="Upload Loan Information"
      subtitle="We have basic information about your loans, but we need more detailed information to process everything correctly."
      className={styles.formContainer}
    >
      <p>Please follow these steps:</p>
      <ol>
        <li>
          Log in to{' '}
          <a href="https://studentaid.gov/fsa-id/sign-in" target="_blank" rel="noreferrer">
            studentaid.gov
          </a>
          .
        </li>
        <li>
          From the dashboard, click the <b>View Details</b> button.
          <img src="/student-aid-images/image1.png" alt="Example" width="100%" />
        </li>
        <li>The screen will refresh.</li>
        <li>
          From the Details Page click the <b>Download My Aid Data</b> button.
          <img src="/student-aid-images/image2.png" alt="Example" width="100%" />
        </li>
      </ol>
      <p>Upload the file below:</p>

      <DropzoneWidget
        handleFiles={renameFiles(handleAddFiles, 'StudentAidData')}
        extensions={['text/plain']}
        multiple={false}
      />

      {files.length ? (
        <Suspense fallback={<ReactLoading type="spin" color="#fff" />}>
          <FilesPreview files={files} handleRemove={handleRemoveFiles} />
        </Suspense>
      ) : (
        <></>
      )}

      {applyProgress?.loanInformation && (
        <>
          <div className={styles.uploaded}>
            <CheckIcon className={styles.checkIcon} />
            <p className={styles.verifiedLabel}>Loan information uploaded</p>
          </div>

          {!files.length && <Button onClick={handleNext}>Next</Button>}
        </>
      )}

      {Boolean(!applyProgress?.loanInformation || files.length) && (
        <Button
          disabled={!files.length}
          className={styles.button}
          onClick={handleContinue}
          isLoading={isLoading || isApplicationStatusUpdating}
        >
          Save and Continue
        </Button>
      )}
    </FormContainer>
  );
};

export default UploadLoanInformation;
