import { PaymentApi } from 'api/PaymentApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorResponse } from './ErrorResponse';

export const createGetStudentLoanApplicationPaymentStatusThunk = (api: PaymentApi) =>
  createAsyncThunk('payment/studentLoanAssitance/status', async (applicationId: string, thunkApi) => {
    try {
      return await api.getStudentLoanAssistancePaymentStatus(applicationId);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
