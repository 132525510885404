import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export interface StudentLoanAssistanceInputData {
  applicationId: string;
}

export const createStudentLoanAssistanceDataThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/getAssistanceData', async (data: StudentLoanAssistanceInputData, thunkApi) => {
    try {
      const result = await api.getStudentLoanAssistanceData(data.applicationId);
      return result;
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
