import { CurrentFlow } from 'enums/CurrentFlow';
import { useSelector } from 'react-redux';
import { getApplicationStep } from 'selectors/getApplicationStep';

const useCurrentFlow = () => {
  const { currentFlow } = useSelector(getApplicationStep);

  return {
    currentFlow,
    isCardFlow: currentFlow === CurrentFlow.Card,
    isFinancialCheckupFlow: currentFlow === CurrentFlow.FinancialCheckup,
    isMissedPaymentFlow: currentFlow === CurrentFlow.MissedPayment,
  };
};

export default useCurrentFlow;
