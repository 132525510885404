import React from 'react';
import FormContainer from 'components/LoanForm/FormContainer';

import { getAuthData } from 'selectors/getAuthData';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { authOtp } from 'thunks';
import VerifyCode from 'components/VerifyCode';
import { ContinueMode } from 'components/VerifyCode/VerifyCode';

export interface VerifySmsArgs {
  onSuccessfulVerification(): void;
  onResendCode(): void;
}

const VerifySms = (args: VerifySmsArgs) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { phoneId } = useSelector(getAuthData);

  const { onSuccessfulVerification, onResendCode } = args;

  const onVerify = async (code: string) => {
    const authResponse = await dispatchWithUnwrap(authOtp({ code, phoneOrEmailId: phoneId ?? '' }));
    if (authResponse.verified) {
      onSuccessfulVerification();
    }
    return authResponse.verified;
  };

  return (
    <FormContainer
      title="Verify Your Identity"
      subtitle="Enter the 6-digit authorization code sent to phone number we have on file."
    >
      <VerifyCode
        onResendCode={async () => {
          onResendCode();
        }}
        onVerify={onVerify}
        continueMode={ContinueMode.OnCodeValid}
      />
    </FormContainer>
  );
};

export default VerifySms;
