import React, { FC } from 'react';
import { Message } from 'react-hook-form';
import clsx from 'clsx';
import PhoneInput, { Props } from 'react-phone-number-input/input';
import InputError from 'components/InputError';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './PhoneNumberInput.module.scss';

interface PhoneNumberInputProps extends Props {
  value: string | undefined;
  label: string;
  errorMessage?: Message | undefined;
  className?: string;
  country: string;
  disabled?: boolean;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  onChange,
  value,
  label,
  errorMessage,
  className,
  placeholder,
  country,
  name,
  onBlur,
  maxLength,
  disabled,
  ...props
}) => {
  const { themeStyles } = useLayoutTheme();

  return (
    <div className={className}>
      <div className={clsx(styles.inputContainer)}>
        {label && <p className={clsx(styles.label, themeStyles.inputLabel)}>{label}</p>}
        <PhoneInput
          onChange={onChange}
          value={value}
          className={clsx(styles.input, themeStyles.input, !!errorMessage && styles.error)}
          onBlur={onBlur}
          placeholder={placeholder}
          country={country}
          name={name}
          maxLength={maxLength}
          data-neuro-label="phone"
          disabled={disabled}
          {...props}
        />
      </div>
      {!!errorMessage && <InputError errorMessage={errorMessage} />}
    </div>
  );
};

export default PhoneNumberInput;
