import React, { useState } from 'react';

import { DateArray } from 'ics';
import { useSelector } from 'react-redux';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { getStudentLoanScheduleData } from 'selectors/getStudentLoanScheduleData';
import { formatTextDateAndTime } from 'utils/dateUtils';
import { addToCalendar } from 'utils/schedule';

import { ReactComponent as CalendarLogo } from 'images/calendar.svg';

import styles from './ScheduleDetails.module.scss';

const ScheduleDetails = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { eventStartTime } = useSelector(getStudentLoanScheduleData);
  const [addedToCalendar, setAddedToCalendar] = useState(false);

  const handleAddToCalendar = () => {
    const eventStartDate = new Date(eventStartTime!);
    const start: DateArray = [
      eventStartDate.getFullYear(),
      eventStartDate.getMonth() + 1,
      eventStartDate.getDate(),
      eventStartDate.getHours(),
      eventStartDate.getMinutes(),
    ];
    const event = {
      title: 'Plannery and Silver Lion Student Loan Advisors',
      description: 'Student Loan Advisors',
      location: `You'll receive a call from 216-290-5566.`,
      start,
      duration: { minutes: 30 },
    };

    addToCalendar(event);
    setAddedToCalendar(true);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<CalendarLogo />}
        title={`Your session is scheduled for ${formatTextDateAndTime(new Date(eventStartTime!))}`}
        subtitle="You’ll receive text reminders before your session."
      >
        <p className={styles.label}>
          Due to the high demand, please reschedule in advance if you can no longer attend your complimentary session.
        </p>

        <div className={styles.buttonContainer}>
          {addedToCalendar ? (
            <Button className={styles.button} onClick={handleNext}>
              Next
            </Button>
          ) : (
            <>
              <Button className={styles.button} onClick={handleAddToCalendar}>
                Add to Calendar
              </Button>

              <Button type={ButtonType.Secondary} onClick={handleNext}>
                Not Now
              </Button>
            </>
          )}
        </div>
      </FormContainer>
    </>
  );
};

export default ScheduleDetails;
