import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';
import { Environments, getEnvironment } from 'utils/getEnvironment';

const saveApplicationDataMiddleware = ({ getState }: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (action.type.endsWith('fulfilled') || action.type.endsWith('rejected')) {
    if (getEnvironment() === Environments.Staging) {
      console.debug('Saving application data', action.type);
    }

    localStorage.setItem(LocalStorageKeyName.ApplicationState, JSON.stringify(getState()));
  }

  return result;
};

export default saveApplicationDataMiddleware;
