import { createSlice } from '@reduxjs/toolkit';
import { uploadDocuments, uploadEnrollmentForm, uploadPaystubs, uploadStudentAidData } from 'thunks';

interface FilesUpload {
  isLoading: boolean;
}

const initialState: FilesUpload = {
  isLoading: false,
};

const filesUpload = createSlice({
  name: 'filesUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadDocuments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadDocuments.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadDocuments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadEnrollmentForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadEnrollmentForm.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadEnrollmentForm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadPaystubs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadPaystubs.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadPaystubs.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadStudentAidData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadStudentAidData.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadStudentAidData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default filesUpload.reducer;
