import {
  ApplicationStatusUpdateResponse,
  CreateApplicationData,
  CreateApplicationResponse,
  Employer,
  GetApplicationAprResponse,
  GetApplicationDataResponse,
  Tradeline,
} from 'handlers/applicationData';
import { BankAccountDataForLos } from 'handlers/bankAccount';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import Api, { ErrorMode, FetchMethodType } from './Api';

export interface DataForUpdatingBankAccount {
  applicationId: string;
  bankAccountData: BankAccountDataForLos;
}

export interface DataForUpdatingApplicationStatus {
  applicationId: string;
  applicationStatus: ApplicationStatusName;
}

export interface DataForUpdatingApplicationFeedback {
  applicationId: string;
  experience?: string;
  howDidYouHear?: string;
}

export interface DataForUpdatingIdentity {
  applicationId: string;
  citizenship: string;
  socialSecurityNumber?: string;
}

export interface UpdateIdentityResponse {}

export interface DataForUpdatingApplicationLoanManagement {
  applicationId: string;
  missedPaymentReason?: string;
}

export enum TradelineNewState {
  Selected = 'selected',
  Unselected = 'unselected',
}

export type SelectedTradeline = { tradeline: Tradeline; newState: TradelineNewState };

export interface DataForUpdatingTradelines {
  applicationId: string;
  tradelinesToUpdate: SelectedTradeline[];
}

export interface DataForUpdatingAdditionalFunds {
  applicationId: string;
  additionalFundsAmount: number;
}

export interface EmploymentData {
  employment: Employer[];
  isLicensedProfessional: boolean;
  additionalIncome?: number;
  healthcareLicenseNumber?: string;
  healthcareLicenseState?: string;
}

export interface UpdatedEmploymentData {
  applicationId: string;
  employmentData: EmploymentData;
}

export interface ApplicationDataApi {
  getApplicationData(applicationId: string): Promise<GetApplicationDataResponse>;
  getApplicationApr(applicationId: string): Promise<GetApplicationAprResponse>;
  createApplication(data: CreateApplicationData): Promise<CreateApplicationResponse>;
  updateApplicationBankAccountData(data: DataForUpdatingBankAccount): Promise<void>;
  updateApplicationStatus(data: DataForUpdatingApplicationStatus): Promise<ApplicationStatusUpdateResponse>;
  updateApplicationCheckingAccount(applicationId: string, bankAccountId: string): Promise<void>;
  updateApplicationFeedbackData(data: DataForUpdatingApplicationFeedback): Promise<void>;
  updateApplicationIdentityData(data: DataForUpdatingIdentity): Promise<UpdateIdentityResponse>;
  updateApplicationLoanManagementData(data: DataForUpdatingApplicationLoanManagement): Promise<void>;
  updateEmploymentData(data: UpdatedEmploymentData): Promise<GetApplicationDataResponse>;
  updateSelectedTradelines(
    applicationId: string,
    tradelinesToUpdate: SelectedTradeline[],
  ): Promise<GetApplicationDataResponse>;
  updateAdditionalFunds(applicationId: string, additionalFunds: number): Promise<GetApplicationDataResponse>;
  joinDebtConsolidationWaitlist(applicationId: string): Promise<void>;
}

export default class ApplicationDataRestApi extends Api implements ApplicationDataApi {
  public async getApplicationData(applicationId: string) {
    const data = await this.fetch<GetApplicationDataResponse>(`/application/${applicationId}`, {
      method: FetchMethodType.GET,
      errorMode: ErrorMode.HideError,
    });
    return data;
  }

  public async getApplicationApr(applicationId: string) {
    const result = await this.fetch<GetApplicationAprResponse>(`/loanManagement/apr/${applicationId}`, {
      method: FetchMethodType.GET,
    });
    return result;
  }

  public async createApplication(data: CreateApplicationData) {
    const result = await this.fetch<CreateApplicationResponse>(`/application`, {
      method: FetchMethodType.POST,
      body: {
        data,
      },
    });
    return result;
  }

  public async updateApplicationBankAccountData(data: DataForUpdatingBankAccount) {
    await this.fetch<void>(`/bankAccount`, {
      method: FetchMethodType.POST,
      body: {
        data,
      },
      errorMode: ErrorMode.DisplayActualMessage,
    });
  }

  public async updateApplicationStatus(data: DataForUpdatingApplicationStatus) {
    const applicationStatus = await this.fetch<ApplicationStatusUpdateResponse>(`/updateApplicationStatus`, {
      method: FetchMethodType.POST,
      body: {
        data,
      },
    });
    return applicationStatus;
  }

  public async updateApplicationCheckingAccount(applicationId: string, bankAccountId: string): Promise<void> {
    await this.fetch<void>(`/bankAccount/primaryCheckingAccount/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: {
        bankAccountId,
      },
    });
  }

  public async updateApplicationFeedbackData(data: DataForUpdatingApplicationFeedback) {
    await this.fetch<void>('/application/feedback', {
      method: FetchMethodType.PATCH,
      body: {
        data,
      },
    });
  }

  public async updateApplicationIdentityData(data: DataForUpdatingIdentity) {
    const { applicationId, ...values } = data;
    return this.fetch<UpdateIdentityResponse>(`/application/${applicationId}`, {
      method: FetchMethodType.PATCH,
      body: {
        ...values,
      },
    });
  }

  public async updateEmploymentData(data: UpdatedEmploymentData): Promise<GetApplicationDataResponse> {
    const { applicationId, employmentData } = data;
    const application = await this.fetch<GetApplicationDataResponse>(`/employers/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: { ...employmentData },
    });

    return application;
  }

  public async updateSelectedTradelines(applicationId: string, tradelinesToUpdate: SelectedTradeline[]) {
    return (await this.fetch(`/reports/debtProfile/${applicationId}`, {
      method: FetchMethodType.PATCH,
      body: {
        tradelinesToUpdate,
      },
    })) as GetApplicationDataResponse;
  }

  public async updateAdditionalFunds(applicationId: string, additionalFundsAmount: number) {
    return (await this.fetch(`/reports/additionalFunds/${applicationId}`, {
      method: FetchMethodType.PATCH,
      body: {
        additionalFundsAmount,
      },
    })) as GetApplicationDataResponse;
  }

  public async updateApplicationLoanManagementData(data: DataForUpdatingApplicationLoanManagement) {
    await this.fetch<void>(`/application/loanManagement`, {
      method: FetchMethodType.PATCH,
      body: {
        data,
      },
    });
  }

  public async joinDebtConsolidationWaitlist(applicationId: string) {
    await this.fetch<void>(`/application/joinDebtConsolidationWaitlist/${applicationId}`, {
      method: FetchMethodType.POST,
    });
  }
}
