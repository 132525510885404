import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export const createPreviewEcfThunk = (api: StudentLoanApi) =>
  createAsyncThunk('documents/previewEcf', async (applicationId: string, thunkApi) => {
    try {
      return await api.previewEnrollmentForm(applicationId);
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);
      throw error;
    }
  });
