import React from 'react';
import { ReactComponent as ArrowIcon } from 'images/arrow-next.svg';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { StepId, StepProgress, StepsHook } from 'components/Steps/stepUtils';

import styles from './ReturningFlow.module.scss';

export const ReturningFlow = <Id extends StepId, Progress extends StepProgress>(
  useStepsHook: StepsHook<Id, Progress>,
  stepsRoute: RoutePath,
) => {
  const { stepsProgress, stepData } = useStepsHook();
  const navigate = useNavigate();

  const handleContinue = (stepId: Id) => {
    navigate(stepsRoute, {
      state: {
        step: stepId,
      },
    });
  };

  return (
    <div className={styles.container}>
      {stepData.map((step) => (
        <div key={step.id} className={styles.step} onClick={() => handleContinue(step.id)}>
          {stepsProgress[step.id] ? <CheckIcon className={styles.icon} /> : <step.icon className={styles.icon} />}
          <div className={styles.name}>{step.name}</div>
          <ArrowIcon className={styles.arrowIcon} />
        </div>
      ))}
    </div>
  );
};

export default ReturningFlow;
