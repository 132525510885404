import { PaymentApi, MakeACHPaymentData } from 'api/PaymentApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorResponse } from './ErrorResponse';

export const createACHPaymentThunk = (api: PaymentApi) =>
  createAsyncThunk('ach/makePayment', async (data: MakeACHPaymentData, thunkApi) => {
    try {
      return await api.makeACHPayment(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
