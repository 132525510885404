export enum PayFrequencyOptionLabel {
  Weekly = 'Weekly',
  BiWeekly = 'Bi-weekly',
  SemiMonthly = 'Semi-monthly',
  Monthly = 'Monthly',
  Other = 'Other',
}

export enum PayFrequencyOptionValue {
  Weekly = 'weekly', // every week
  BiWeekly = 'biweekly', // every other week
  SemiMonthly = 'semimonthly', // twice a month
  Monthly = 'monthly', // every month
  Other = 'other', // Custom frequency
}

export interface YourIncomeResponse {
  employmentIncome: Record<string, string>[];
}
