import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import { FlowComponentType } from 'routes/types';
import Button from 'components/Button';
import { MohelaResult, StudentLoanForgivenessResults } from 'enums/StudentLoanForgivenessFlowResults';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const options: { id: MohelaResult; label: string }[] = [
  {
    id: MohelaResult.EnrolledWantForgiveness,
    label: 'I am currently enrolled in PSLF, but I want to understand my forgiveness status',
  },
  { id: MohelaResult.AppliedWantEnrollment, label: 'I’ve applied for PSLF, but don’t know my enrollment status' },
  { id: MohelaResult.NotApplied, label: 'I’ve not applied for PSLF' },
  { id: MohelaResult.NotSure, label: 'I’m not sure' },
];

const Mohela = ({ flags, navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const [selected, setSelected] = useState<StudentLoanForgivenessResults | null>(null);

  const handleNext = () => {
    analytics.track('Student Loan MOHELA Selected', { selected });
    onNext(selected!);
  };
  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <QuestionsWrapper>
          <>
            <RadioButtonList
              label="We noticed MOHELA is your current servicer, which is the servicer for Public Service Loan Forgiveness."
              selected={selected}
              setSelected={setSelected}
              options={options}
            />

            <Button disabled={Boolean(!selected)} onClick={handleNext} className={styles.button}>
              Next
            </Button>
          </>
        </QuestionsWrapper>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default Mohela;
