import Api, { ErrorMode, FetchMethodType } from './Api';

export interface SendSmsCodeData {
  phoneNumber: string;
}

export interface SendEmailCodeData {
  phoneNumber: string;
  email: string;
}

export interface SendSmsCodeForApplicationIdData {
  applicationId: string;
}

export interface SendSmsCodeResponse {
  phoneId: string;
}

export interface SendEmailCodeResponse {
  emailId: string;
}

export interface AuthOtpData {
  phoneOrEmailId: string;
  code: string;
}

export type AuthOtpResponse =
  | {
      verified: false;
    }
  | {
      verified: true;
      sessionToken: string;
    };

export interface AuthSessionData {
  sessionToken: string;
}

export type AuthSessionResponse =
  | {
      authenticated: false;
    }
  | {
      authenticated: true;
      phoneNumber: string;
    };

export interface AuthApi {
  sendSmsCode(data: SendSmsCodeData): Promise<SendSmsCodeResponse>;
  sendSmsCodeForApplicationId(data: SendSmsCodeForApplicationIdData): Promise<SendSmsCodeResponse>;
  sendEmailCode(data: SendEmailCodeData): Promise<SendEmailCodeResponse>;
  authOtp(data: AuthOtpData): Promise<AuthOtpResponse>;
  authSession(data: AuthSessionData): Promise<AuthSessionResponse>;
}

export default class AuthRestApi extends Api implements AuthApi {
  async sendSmsCode(data: SendSmsCodeData): Promise<SendSmsCodeResponse> {
    return this.fetch<SendSmsCodeResponse>('/auth/sendSmsCode', {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  async sendSmsCodeForApplicationId(data: SendSmsCodeForApplicationIdData): Promise<SendSmsCodeResponse> {
    return this.fetch<SendSmsCodeResponse>('/auth/sendSmsCodeForApplicationId', {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  async authOtp(data: AuthOtpData): Promise<AuthOtpResponse> {
    return this.fetch<AuthOtpResponse>(`/auth/authOtp`, {
      method: FetchMethodType.POST,
      body: data,
      errorMode: ErrorMode.HideError,
      redirectToLogin: false,
    });
  }

  async sendEmailCode(data: SendEmailCodeData): Promise<SendEmailCodeResponse> {
    return this.fetch<SendEmailCodeResponse>('/auth/sendEmailCode', {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  async authSession(data: AuthSessionData): Promise<AuthSessionResponse> {
    return this.fetch<AuthSessionResponse>(`/auth/authSession?sessionToken=${encodeURIComponent(data.sessionToken)}`, {
      method: FetchMethodType.GET,
    });
  }
}
