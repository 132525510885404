import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingBankAccount } from 'api/ApplicationDataApi';

import { ErrorResponse } from './ErrorResponse';

export const createBankAccountThunk = (api: ApplicationDataApi) =>
  createAsyncThunk(
    'applicationData/updateApplicationBankAccount',
    async (data: DataForUpdatingBankAccount, thunkApi) => {
      try {
        await api.updateApplicationBankAccountData(data);
      } catch (error: unknown) {
        thunkApi.rejectWithValue((error as ErrorResponse).response.data);

        throw error;
      }
    },
  );
