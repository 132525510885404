import { ArgyleApi } from 'api/ArgyleApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorResponse } from './ErrorResponse';

export const createPayDistributionConfigsEncryptThunk = (api: ArgyleApi) =>
  createAsyncThunk(
    'argylePayDistributionConfigs/getArgylePayDistributionConfigs',
    async (applicationId: string, thunkApi) => {
      try {
        const data = await api.getPayDistributionConfigsEncrypt(applicationId);
        return data;
      } catch (error: unknown) {
        return thunkApi.rejectWithValue((error as ErrorResponse).response.data);
      }
    },
  );
