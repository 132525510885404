import Authorization from 'components/MissedPayments/Authorization/Authorization';
import MakeAPayment from 'components/MissedPayments/MakeAPayment/MakeAPayment';
import MakingPayment from 'components/MissedPayments/MakingPayment/MakingPayment';
import MissedPayment from 'components/MissedPayments/MissedPayment/MissedPayment';
import MissedPaymentReason from 'components/MissedPayments/MissedPaymentReason/MissedPaymentReason';
import PaymentFailed from 'components/MissedPayments/PaymentFailed/PaymentFailed';
import PaymentInitiated from 'components/MissedPayments/PaymentInitiated/PaymentInitiated';
import WaitingAuthorization from 'components/MissedPayments/WaitingAuthorization/WaitingAuthorization';
import BankAccount from 'components/Verification/Steps/CheckingAccount/BankAccount';
import SelectAccount from 'components/Verification/Steps/CheckingAccount/SelectAccount';
import Plaid from 'components/Verification/Steps/CheckingAccount/Plaid';
import { CheckingAccountResult, MakingPaymentResult } from 'enums/FlowNextResults';
import { RoutePath } from 'enums/Routes';

import { RouterType } from './types';

export const getMissedPaymentRoutes = (): RouterType => ({
  [RoutePath.MissedPayment]: {
    navigationInfo: { showBackLink: false, title: 'Missed Payment' },
    component: MissedPayment,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.MissedPaymentReason);
    },
  },
  [RoutePath.MissedPaymentReason]: {
    navigationInfo: { showBackLink: true, title: 'Reason For Missed Payment' },
    component: MissedPaymentReason,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.MakeAPayment);
    },
  },
  [RoutePath.MakeAPayment]: {
    navigationInfo: { showBackLink: true, title: 'Make a Payment' },
    component: MakeAPayment,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Plaid);
    },
  },
  [RoutePath.Plaid]: {
    navigationInfo: { showBackLink: true, title: 'Bank Account' },
    component: Plaid,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case CheckingAccountResult.Continue:
          navigate(RoutePath.Authorization);
          break;
        default:
          navigate(RoutePath.Error);
      }
    },
  },
  [RoutePath.BankAccount]: {
    navigationInfo: { showBackLink: true, title: 'Bank Account' },
    component: BankAccount,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Authorization);
    },
  },
  [RoutePath.PlaidCheckingAccounts]: {
    navigationInfo: { showBackLink: true, title: 'Bank Account' },
    component: SelectAccount,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Authorization);
    },
  },
  [RoutePath.Authorization]: {
    navigationInfo: { showBackLink: false },
    component: Authorization,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.MakingPayment);
    },
  },
  [RoutePath.WaitingAuthorization]: {
    navigationInfo: { showBackLink: false },
    component: WaitingAuthorization,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.MakingPayment);
    },
  },
  [RoutePath.MakingPayment]: {
    navigationInfo: { showBackLink: false },
    component: MakingPayment,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case MakingPaymentResult.Success:
          navigate(RoutePath.PaymentInitiated);
          break;
        case MakingPaymentResult.Failure:
          navigate(RoutePath.PaymentFailed);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.PaymentInitiated]: {
    navigationInfo: { showBackLink: false, title: 'Payment Initiated' },
    component: PaymentInitiated,
    handleNext: () => () => {
      window.open('https://www.planneryapp.com/');
    },
  },
  [RoutePath.PaymentFailed]: {
    navigationInfo: { showBackLink: true, title: 'Payment Error' },
    component: PaymentFailed,
  },
});
