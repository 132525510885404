import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthOtpResponse, SendEmailCodeResponse, SendSmsCodeResponse } from 'api/AuthApi';
import { authOtp, sendEmailCode, sendSmsCode, sendSmsCodeForApplicationId } from 'thunks';

export interface AuthData {
  phoneId?: string;
  emailId?: string;
  sessionToken?: string;
}

const initialState: AuthData = {};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendSmsCode.fulfilled, (state, { payload }: PayloadAction<SendSmsCodeResponse>) => {
      state.phoneId = payload.phoneId;
    });
    builder.addCase(sendSmsCodeForApplicationId.fulfilled, (state, { payload }: PayloadAction<SendSmsCodeResponse>) => {
      state.phoneId = payload.phoneId;
    });
    builder.addCase(sendEmailCode.fulfilled, (state, { payload }: PayloadAction<SendEmailCodeResponse>) => {
      state.emailId = payload.emailId;
    });
    builder.addCase(authOtp.fulfilled, (state, { payload }: PayloadAction<AuthOtpResponse>) => {
      if (payload.verified) {
        state.sessionToken = payload.sessionToken;
      }
    });
  },
});

export default auth.reducer;
