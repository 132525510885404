import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingTradelines } from 'api/ApplicationDataApi';

export const createUpdateSelectedTradelinesThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/updateSelectedTradelines', async (data: DataForUpdatingTradelines, thunkApi) => {
    try {
      return await api.updateSelectedTradelines(data.applicationId, data.tradelinesToUpdate);
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
