import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataForVerifyingApplication, LoanOfferApi } from 'api/LoanOfferApi';

import { ErrorResponse } from './ErrorResponse';

export const createVerifyApplicationThunk = (api: LoanOfferApi) =>
  createAsyncThunk('loanOffer/verifyApplication', async (data: DataForVerifyingApplication, thunkApi) => {
    try {
      return await api.verifyApplication(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);
      throw error;
    }
  });
