import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerName } from 'enums/PartnerName';
import { getApplicationData } from 'thunks';

import { GetApplicationDataResponse } from './applicationData';

export interface PreQualificationData {
  borrower_state_or_province?: string;
  loan_amount_passed?: boolean;
  loan_purpose?: string;
  credit_score_passed?: boolean;
  loan_term_in_months?: number;
  partnerName?: PartnerName;
  loan_goal?: string;
  benefit?: boolean;
}

const initialState: PreQualificationData = {
  loan_amount_passed: false,
  credit_score_passed: false,
  loan_term_in_months: 36,
};

const preQualificationData = createSlice({
  name: 'preQualification',
  initialState,
  reducers: {
    setStateData: (state: PreQualificationData, { payload }: PayloadAction<string | undefined>) => {
      state.borrower_state_or_province = payload;
      analytics.identify({ borrowerState: payload });
    },
    setLoanPurposeData: (state: PreQualificationData, { payload }: PayloadAction<PreQualificationData>) => {
      state.loan_purpose = payload.loan_purpose;
      analytics.identify({ loanPurpose: payload.loan_purpose });
    },
    setPartnerName: (state: PreQualificationData, { payload }: PayloadAction<PreQualificationData>) => {
      state.partnerName = payload.partnerName;
      typeof payload.benefit === 'boolean' && (state.benefit = payload.benefit);
    },
    setGoal: (state: PreQualificationData, { payload }: PayloadAction<string>) => {
      state.loan_goal = payload;
      analytics.identify({ loanGoal: payload });
    },
    setLoanAmountPassed: (state: PreQualificationData, { payload }: PayloadAction<boolean>) => {
      state.loan_amount_passed = payload;
    },
    setCreditScorePassed: (state: PreQualificationData, { payload }: PayloadAction<boolean>) => {
      state.credit_score_passed = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      const { partner } = payload.application;
      if (partner) {
        state.partnerName = partner.toLowerCase() as PartnerName;
      }
    });
  },
});

export const {
  setStateData,
  setLoanPurposeData,
  setPartnerName,
  setLoanAmountPassed,
  setCreditScorePassed,
  setGoal,
} = preQualificationData.actions;

export default preQualificationData.reducer;
