import React from 'react';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';
import DebtRow from 'components/FinancialCheckup/YourFinances/Debts/DebtRow';
import { Debt } from 'handlers/applicationData';

import styles from './DebtCategories.module.scss';

interface DebtCategoriesProps {
  debtItems: Debt[];
  totalDebt?: number;
  title: string;
  icon?: 'warning' | 'check';
}

const DebtCategories = ({ title, debtItems, totalDebt, icon = 'check' }: DebtCategoriesProps) => (
  <div className={styles.innerContainer}>
    <div className={styles.header}>
      <p className={styles.sectionTitle}>{title}</p>
      {icon === 'check' ? <CheckIcon className={styles.icon} /> : <WarningIcon className={styles.icon} />}
    </div>

    {debtItems.map((debt, index) => (
      <DebtRow index={index} debt={debt} totalBalance={totalDebt ?? 0} key={debt.name.trim()} />
    ))}
  </div>
);

export default DebtCategories;
