import React, { FC, useCallback, useEffect, useState } from 'react';
import { HardOfferData } from 'handlers/applicationData';

import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import NumericStepper, { NumericStepperFormat } from 'components/Common/NumericStepper/NumericStepper';
import { getApplicationData } from 'selectors/getApplicationData';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationApr, updateAdditionalFunds } from 'thunks';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { debounce } from 'utils/debounce';

import styles from './AdditionalFunds.module.scss';

interface AdditionalFundsProps {
  setSectionValid: (valid: boolean) => void;
}

enum WillBorrow {
  Yes = 'yes',
  No = 'no',
}

const AdditionalFunds: FC<AdditionalFundsProps> = ({ setSectionValid }): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application, isLoading } = useSelector(getApplicationData);

  const hardOffer = (application?.hardOffer ?? {}) as Partial<HardOfferData>;
  const additionalFundsEligibility = application?.additionalFundsEligibility;

  const { additionalFundsTotal, payOffTotal, manuallyUpdatedAdditionalFunds } = hardOffer;
  const additionalFunds = additionalFundsTotal?.amount ?? 0;
  const willBorrow = getDefaultWillBorrow(manuallyUpdatedAdditionalFunds, additionalFunds);

  const [internalLoading, setInternalLoading] = useState<boolean>(false);
  const [additionalFundsSelected, setAdditionalFundsSelected] = useState<number>(additionalFunds);

  const maxLoanAmount = application?.maxLoanAmount ?? 0;
  const minLoanAmount = application?.minLoanAmount ?? 0;
  const totalDebt = payOffTotal?.amount ?? 0;
  const additionalFundsAvailable = maxLoanAmount - totalDebt;

  const handleAdditionalFundsChange = async (newAdditionalFunds: number) => {
    setInternalLoading(true);
    setAdditionalFundsSelected(newAdditionalFunds);
    await dispatchWithUnwrap(
      updateAdditionalFunds({
        applicationId: application!.id,
        additionalFundsAmount: newAdditionalFunds,
      }),
    );
    await dispatchWithUnwrap(getApplicationApr(application!.id));
  };

  const debouncedHandleAdditionalFundsChange = useCallback(debounce(handleAdditionalFundsChange, 750), []);

  useEffect(() => {
    if (!isLoading) {
      setAdditionalFundsSelected(additionalFunds);
      setInternalLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    // We only enforce selection in case #2
    if (totalDebt > minLoanAmount && !(totalDebt === maxLoanAmount && additionalFunds === 0)) {
      setSectionValid(willBorrow !== null);
    } else {
      setSectionValid(true);
    }
  }, [isLoading, willBorrow]);

  if (isLoading && !internalLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#9d86f9" size={68} />
      </div>
    );
  }

  // 1st case, we don't show additional funds
  if (!additionalFundsEligibility || (totalDebt === maxLoanAmount && additionalFunds === 0)) {
    return <></>;
  }

  const numericStepper = (
    <NumericStepper
      options={{ format: NumericStepperFormat.Currency }}
      isLoading={internalLoading}
      value={
        willBorrow === WillBorrow.Yes || totalDebt <= minLoanAmount ? additionalFundsSelected : additionalFundsAvailable
      }
      minValue={Math.max(0, minLoanAmount - totalDebt)}
      maxValue={additionalFundsAvailable}
      setValue={(value) => {
        setAdditionalFundsSelected(value);
        debouncedHandleAdditionalFundsChange(value);
      }}
    />
  );

  // 2nd case: Consolidating debt over the minimum loan amount
  if (totalDebt > minLoanAmount) {
    return (
      <div className={styles.additionalFundsContainer}>
        <div className={styles.question}>
          Would you like to borrow any additional money? <span className={styles.required}>*</span>
        </div>
        <RadioButtonList
          customization={{ smallCheckboxes: true }}
          options={[
            {
              id: WillBorrow.Yes,
              label: <>Yes, borrow an additional {numericStepper}</>,
            },
            { id: WillBorrow.No, label: 'No' },
          ]}
          selected={willBorrow}
          setSelected={(id) => {
            if (id !== willBorrow) {
              if (id === WillBorrow.Yes) {
                handleAdditionalFundsChange(additionalFundsAvailable);
              } else {
                handleAdditionalFundsChange(0);
              }
            }
          }}
        />
      </div>
    );
  }

  // 3rd case: Consolidating debt under the minimum loan amount and the minimum loan amount is the same as the maximum
  if (maxLoanAmount === minLoanAmount) {
    return (
      <div className={styles.additionalFundsContainer}>
        <div>{formatMonetaryAmount(additionalFundsSelected)} will be lent to you as additional funds</div>
        <div className={styles.note}>
          {formatMonetaryAmount(additionalFundsSelected)} is the minimum amount needed to reach our{' '}
          {formatMonetaryAmount(minLoanAmount)} loan amount minimum.
        </div>
      </div>
    );
  }

  // 3rd case: Consolidating debt under the minimum loan amount
  if (totalDebt > 0) {
    return (
      <div className={styles.additionalFundsContainer}>
        <div>Borrow an additional {numericStepper}</div>
        {additionalFundsSelected + totalDebt === minLoanAmount && minLoanAmount !== maxLoanAmount && (
          <div className={styles.note}>
            {formatMonetaryAmount(additionalFundsSelected)} is the minimum amount needed to reach our{' '}
            {formatMonetaryAmount(minLoanAmount)} loan amount minimum.
          </div>
        )}
      </div>
    );
  }

  // 4th case: No debt to consolidate
  return (
    <div className={styles.additionalFundsContainer}>
      <div className={styles.note}>
        You have no accounts selected for consolidation. However, you can borrow between{' '}
        {formatMonetaryAmount(minLoanAmount)} and {formatMonetaryAmount(maxLoanAmount)}.
      </div>
      <div>Borrow {numericStepper}</div>
    </div>
  );
};

const getDefaultWillBorrow = (manuallyUpdatedAdditionalFunds: boolean | undefined, additionalFunds: number) => {
  if (!manuallyUpdatedAdditionalFunds) {
    return additionalFunds > 0 ? null : WillBorrow.No;
  }
  return additionalFunds > 0 ? WillBorrow.Yes : WillBorrow.No;
};

export default AdditionalFunds;
