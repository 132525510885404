import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'components/Checkbox';
import NumberInput from 'components/NumberInput';
import { clearSecondaryEmployer, initialState, setYourEmployerData } from 'handlers/yourEmployer';
import { getMessageForRequiredFields } from 'utils/errors';

import { getYourEmployer } from 'selectors/yourEmployer';
import Input from 'components/Input';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import Anchor from 'components/Common/Buttons/Anchor';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { updateEmploymentData } from 'thunks';
import { getApplicationData } from 'selectors/getApplicationData';
import { getYearsFromNow } from 'utils/dateUtils';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { EmploymentDetailsResult } from 'enums/FlowNextResults';
import InputSelect from 'components/InputSelect';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import { PayFrequencyOptionLabel, PayFrequencyOptionValue } from 'handlers/yourTotalIncome';
import { InputSelectOption } from 'components/InputSelect/InputSelect';

import styles from './EmploymentDetails.module.scss';

enum YourEmployerInputLabel {
  EmployerName = 'employer name',
  HireDate = 'Start of employment',
  JobTitle = 'Your job title',
  AnnualIncome = 'Gross annual income',
  PayFrequency = 'Pay Frequency',
  AdditionalIncome = 'Additional income',
  IsLicensedProfessional = 'I am a licensed healthcare professional',
  HealthcareLicenseNumber = 'Healthcare license number',
  HealthcareLicenseState = 'Healthcare license state',
}

export const PAY_FREQUENCY_OPTIONS = [
  { label: PayFrequencyOptionLabel.Weekly, value: PayFrequencyOptionValue.Weekly },
  { label: PayFrequencyOptionLabel.BiWeekly, value: PayFrequencyOptionValue.BiWeekly },
  { label: PayFrequencyOptionLabel.SemiMonthly, value: PayFrequencyOptionValue.SemiMonthly },
  { label: PayFrequencyOptionLabel.Monthly, value: PayFrequencyOptionValue.Monthly },
  { label: PayFrequencyOptionLabel.Other, value: PayFrequencyOptionValue.Other },
] as InputSelectOption[];

export const ANNUAL_INCOME_MAX_LENGTH = 14;
export const MONETARY_PREFIX = '$';

const EMPLOYER_NUMBER = Object.freeze(['Primary', 'Secondary']);

const EmploymentDetails = ({ handleNext }: StepComponent) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application } = useSelector(getApplicationData);
  const { id: applicationId, totalAnnualIncome } = application!;

  const { isFinancialCheckupFlow } = useCurrentFlow();

  const {
    isLicensedProfessional,
    isLoading,
    additionalIncome,
    employer1,
    employer2,
    healthcareLicenseNumber,
    healthcareLicenseState,
  } = useSelector(getYourEmployer);

  const [secondaryEmployerEnabled, setSecondaryEmployerEnabled] = useState(!!employer2.employerName);
  const [additionalIncomeEnabled, setAdditionalIncomeEnabled] = useState(!!additionalIncome);

  const { annualIncome, ...employer1Rest } = employer1;

  const {
    formState: { isValid, errors },
    trigger,
    register,
    unregister,
    watch,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      employer1: { annualIncome: annualIncome ?? totalAnnualIncome, ...employer1Rest },
      employer2: { ...employer2 },
      isLicensedProfessional,
      additionalIncome,
      healthcareLicenseNumber,
      healthcareLicenseState,
    },
  });

  const watcher = watch();

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name, event.target.value.trim());
    trigger(event.target.name);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name, event.target.value);
    trigger(event.target.name);
  };

  useEffect(() => {
    register(`employer1.employerName`, {
      required: getMessageForRequiredFields(YourEmployerInputLabel.EmployerName),
    });
    register(`employer1.jobTitle`, {
      required: getMessageForRequiredFields(YourEmployerInputLabel.JobTitle),
    });
    register(`employer1.annualIncome`, {
      required: getMessageForRequiredFields(YourEmployerInputLabel.AnnualIncome),
    });
    register(`employer1.payFrequency`, {
      required: getMessageForRequiredFields(YourEmployerInputLabel.PayFrequency),
    });

    register('additionalIncome', {
      required: false,
    });

    register(`isLicensedProfessional`, {
      required: false,
    });

    register('healthcareLicenseNumber', {
      required: getMessageForRequiredFields(YourEmployerInputLabel.HealthcareLicenseNumber),
    });

    register('healthcareLicenseState', {
      required: getMessageForRequiredFields(YourEmployerInputLabel.HealthcareLicenseState),
    });

    if (secondaryEmployerEnabled) {
      register(`employer2.employerName`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.EmployerName),
      });
      register(`employer2.jobTitle`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.JobTitle),
      });
      register(`employer2.annualIncome`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.AnnualIncome),
      });
      register(`employer2.hireDate`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.HireDate),
      });
    } else {
      unregister(`employer2.employerName`);
      unregister(`employer2.jobTitle`);
      unregister(`employer2.annualIncome`);
      unregister(`employer2.hireDate`);
    }
  }, [watcher, register, secondaryEmployerEnabled, isFinancialCheckupFlow]);

  const handleContinue = async () => {
    dispatch(setYourEmployerData(watcher));
    const applicationData = await dispatchWithUnwrap(
      updateEmploymentData({
        applicationId,
        employmentData: {
          employment: [
            {
              employerName: watcher.employer1.employerName,
              jobTitle: watcher.employer1.jobTitle,
              annualIncome:
                watcher.employer1.annualIncome !== undefined
                  ? Number(`${watcher.employer1.annualIncome}`.replace(/[^0-9.-]+/g, ''))
                  : undefined,
              payFrequency: watcher.employer1.payFrequency,
            },
            {
              employerName: watcher.employer2?.employerName,
              jobTitle: watcher.employer2?.jobTitle,
              annualIncome:
                watcher.employer2?.annualIncome !== undefined
                  ? Number(`${watcher.employer2.annualIncome}`.replace(/[^0-9.-]+/g, ''))
                  : undefined,
              hireDate: watcher.employer2?.hireDate,
            },
          ],
          isLicensedProfessional: watcher.isLicensedProfessional,
          healthcareLicenseNumber: watcher.healthcareLicenseNumber,
          healthcareLicenseState: watcher.healthcareLicenseState,
          additionalIncome:
            watcher.additionalIncome !== undefined
              ? Number(`${watcher.additionalIncome}`.replace(/[^0-9.-]+/g, ''))
              : undefined,
        },
      }),
    );

    analytics.track('Employment Details Submitted');
    if (applicationData?.application.status !== ApplicationStatusName.Rejected) {
      handleNext(EmploymentDetailsResult.Continue);
    } else {
      handleNext(EmploymentDetailsResult.Rejected);
    }
  };

  const handleAddEmployer = () => {
    setSecondaryEmployerEnabled(true);
  };

  const handleDeleteEmployer = () => {
    setSecondaryEmployerEnabled(false);
    dispatch(clearSecondaryEmployer());
    reset({
      isLicensedProfessional: watcher.isLicensedProfessional,
      employer1: watcher.employer1,
      employer2: initialState.employer2,
    });
  };

  const handleAddAdditionalIncome = () => {
    setAdditionalIncomeEnabled(true);
  };

  const renderEmployerInputs = (employerNumber: 1 | 2) => {
    const key = `employer${employerNumber}` as 'employer1' | 'employer2';
    const employer = employerNumber === 1 ? employer1 : employer2;
    return (
      <div className={styles.inputs}>
        <Input
          label={`${EMPLOYER_NUMBER[employerNumber - 1]} ${YourEmployerInputLabel.EmployerName}`}
          placeholder="Employer name"
          errorMessage={errors?.[key]?.employerName?.message}
          className={styles.formInput}
          name={`${key}.employerName`}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[key]?.employerName}
          autoComplete="organization"
          disabled={employer?.verifiedName}
        />
        {employerNumber === 2 && (
          <DatePicker
            maxDate={getYearsFromNow(0)}
            minDate={getYearsFromNow(-30)}
            placeHolder="MM/DD/YYYY"
            label="Start of employment"
            className={styles.formInput}
            selected={watcher.employer2?.hireDate ? new Date(watcher.employer2?.hireDate) : null}
            onChange={(date) => {
              setValue(`${key}.hireDate`, date);
              trigger(`${key}.hireDate`);
            }}
            errorMessage={errors?.[key]?.hireDate?.message}
            name={`${key}.hireDate`}
            disabled={employer?.verifiedHireDate}
          />
        )}
        <Input
          label={YourEmployerInputLabel.JobTitle}
          placeholder="Job title"
          errorMessage={errors?.[key]?.jobTitle?.message}
          className={styles.formInput}
          name={`${key}.jobTitle`}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[key]?.jobTitle}
          autoComplete="organization-title"
        />
        <NumberInput
          label={YourEmployerInputLabel.AnnualIncome}
          prefix="$"
          placeholder="$0"
          errorMessage={errors?.[key]?.annualIncome?.message}
          thousandSeparator
          className={styles.formInput}
          name={`${key}.annualIncome`}
          onBlur={onBlur}
          onChange={onChange}
          value={`$${watcher[key]?.annualIncome}`}
          maxLength={ANNUAL_INCOME_MAX_LENGTH}
          dataNeuroLabel={`income--${key}`}
          disabled={employer?.verifiedAnnualIncome}
        />
        {employerNumber === 1 && (
          <InputSelect
            label={YourEmployerInputLabel.PayFrequency}
            options={PAY_FREQUENCY_OPTIONS}
            onChange={(option) => {
              const eventName = `${key}.payFrequency`;
              setValue(eventName, option.value);
              trigger(eventName);
            }}
            placeholder="Pay Frequency"
            className={styles.formInput}
            name={`${key}.payFrequency`}
            value={watcher[key]?.payFrequency}
          />
        )}
      </div>
    );
  };

  return (
    <FormContainer title="Employment Details" subtitle="Where do you work?">
      {renderEmployerInputs(1)}

      {!secondaryEmployerEnabled && (
        <Anchor
          disabled={!isValid}
          className={styles.secondEmployerButton}
          isLoading={false}
          onClick={handleAddEmployer}
        >
          + Add another employer
        </Anchor>
      )}

      {secondaryEmployerEnabled && (
        <>
          <div className={styles.divider} />
          {renderEmployerInputs(2)}
          <Anchor className={styles.secondEmployerButton} isLoading={false} onClick={handleDeleteEmployer}>
            <div className={styles.cross}>+</div>Delete second employer
          </Anchor>
        </>
      )}

      {!additionalIncomeEnabled && (
        <Anchor
          disabled={!isValid}
          className={styles.secondEmployerButton}
          isLoading={false}
          onClick={handleAddAdditionalIncome}
        >
          + Add additional income
        </Anchor>
      )}

      {additionalIncomeEnabled && (
        <>
          <div className={styles.divider} />
          <div className={styles.inputs}>
            <NumberInput
              label={YourEmployerInputLabel.AdditionalIncome}
              prefix="$"
              placeholder="$0"
              thousandSeparator
              className={styles.formInput}
              name="additionalIncome"
              onBlur={onBlur}
              onChange={onChange}
              value={`$${watcher.additionalIncome}`}
              maxLength={ANNUAL_INCOME_MAX_LENGTH}
            />
            <p className={styles.additionalIncomeText}>
              Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
              considered as a basis for repaying this obligation.
            </p>
          </div>
        </>
      )}

      <hr className={styles.divider} />

      <Checkbox
        label={YourEmployerInputLabel.IsLicensedProfessional}
        className={styles.checkbox}
        checked={watcher.isLicensedProfessional}
        onChange={() => {
          setValue('isLicensedProfessional', !watcher.isLicensedProfessional);
        }}
      />

      <Input
        label={YourEmployerInputLabel.HealthcareLicenseNumber}
        placeholder={YourEmployerInputLabel.HealthcareLicenseNumber}
        errorMessage={errors?.healthcareLicenseNumber?.message}
        className={styles.formInput}
        name="healthcareLicenseNumber"
        onBlur={onBlur}
        onChange={onChange}
        value={watcher.healthcareLicenseNumber}
      />

      <Input
        label={YourEmployerInputLabel.HealthcareLicenseState}
        placeholder={YourEmployerInputLabel.HealthcareLicenseState}
        errorMessage={errors?.healthcareLicenseState?.message}
        className={styles.formInput}
        name="healthcareLicenseState"
        onBlur={onBlur}
        onChange={onChange}
        value={watcher.healthcareLicenseState}
      />

      <Button isLoading={isLoading} disabled={!isValid} onClick={handleContinue}>
        Save and Continue
      </Button>
    </FormContainer>
  );
};

export default EmploymentDetails;
