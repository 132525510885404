import React, { useState } from 'react';
import OtpInput from 'components/OtpInput';

import Button from 'components/Button';
import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';
import { ButtonType } from 'components/Button/Button';

import styles from './VerifyCode.module.scss';

export enum ContinueMode {
  Manual,
  OnEnterKey,
  OnCodeValid,
}

export interface Props {
  onResendCode(): Promise<void>;
  onVerify(code: string): Promise<boolean>;
  continueMode?: ContinueMode;
  consent?: JSX.Element;
  noAutoComplete?: boolean;
}

const VerifyCode = ({ onResendCode, onVerify, continueMode, consent, noAutoComplete }: Props) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [codeIsValid, setCodeIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [failedAuth, setFailedAuth] = useState(false);

  function handleVerificationCodeChange(code: string) {
    const isValid = code.trim().length === 6;

    setCodeIsValid(isValid);
    setVerificationCode(code);

    if (isValid && continueMode === ContinueMode.OnCodeValid) {
      handleContinue(code);
    }
  }

  const handleContinue = async (code: string) => {
    if (isLoading) {
      return;
    }
    setFailedAuth(false);
    setIsLoading(true);
    try {
      const result = await onVerify(code);
      if (result) {
        return;
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
    setVerificationCode('');
    setFailedAuth(true);
  };

  const handleResendCode = async () => {
    if (isLoading) {
      return false;
    }
    setIsLoading(true);
    await onResendCode();
    setIsLoading(false);
    setFailedAuth(false);
  };

  return (
    <>
      <OtpInput
        name="code"
        className={styles.formInput}
        onChange={(value) => handleVerificationCodeChange(value)}
        value={verificationCode}
        valueLength={6}
        disabled={isLoading}
        autoFocus
        noAutoComplete={noAutoComplete}
        onKeyUp={(e) =>
          e.key === 'Enter' &&
          codeIsValid &&
          continueMode === ContinueMode.OnEnterKey &&
          handleContinue(verificationCode)
        }
      />
      {failedAuth && (
        <div className={styles.error}>
          <WarningIcon /> Invalid code, please check if you entered it correctly.
        </div>
      )}

      {consent}

      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          disabled={isLoading || !codeIsValid}
          isLoading={isLoading}
          onClick={() => {
            handleContinue(verificationCode);
          }}
        >
          {consent ? 'Agree and Continue' : 'Continue'}
        </Button>

        <Button
          className={styles.button}
          disabled={isLoading}
          onClick={handleResendCode}
          isLoading={isLoading}
          type={ButtonType.Secondary}
        >
          Resend code
        </Button>
      </div>
    </>
  );
};

export default VerifyCode;
