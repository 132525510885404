import React from 'react';

import FormContainer from 'components/LoanForm/FormContainer';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { authSessionThunk, authOtp, sendSmsCode } from 'thunks';
import { useSelector } from 'react-redux';
import { getAuthData } from 'selectors/getAuthData';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { Environments, getEnvironment } from 'utils/getEnvironment';
import VerifyCode from 'components/VerifyCode';
import { ContinueMode } from 'components/VerifyCode/VerifyCode';

import styles from './VerifyPhoneNumber.module.scss';

const VerifyPhoneNumber = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { phoneId } = useSelector(getAuthData);
  const { phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  const onResendCode = async () => {
    await dispatchWithUnwrap(sendSmsCode({ phoneNumber }));
  };

  const onVerify = async (code: string) => {
    const authResponse = await dispatchWithUnwrap(authOtp({ code, phoneOrEmailId: phoneId! }));
    if (authResponse.verified) {
      // this is needed for a future step, will wait for this to resolve when it's needed
      dispatchWithUnwrap(
        authSessionThunk({
          phoneNumber,
          firstName,
          lastName,
          sessionToken: authResponse.sessionToken,
        }),
      );
      handleNext();
    }
    return authResponse.verified;
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Verify and Consent" subtitle="Enter the 6-digit authorization code sent to your phone.">
        <VerifyCode
          onResendCode={onResendCode}
          onVerify={onVerify}
          continueMode={getEnvironment() === Environments.Staging ? ContinueMode.OnEnterKey : ContinueMode.Manual}
          consent={
            <p className={styles.consent}>
              By clicking Agree and Continue, I hereby consent to the{' '}
              <a href="https://www.planneryapp.com/esign-agreement" target="_blank" rel="noreferrer">
                E-Sign Agreement
              </a>
              ,{' '}
              <a href="https://www.planneryapp.com/terms-of-service" target="_blank" rel="noreferrer">
                Terms of Service
              </a>
              ,{' '}
              <a href="https://www.planneryapp.com/privacy-policy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              , and{' '}
              <a href="https://www.planneryapp.com/communication-policy" target="_blank" rel="noreferrer">
                Communication Policy
              </a>{' '}
              and I am providing written consent under the FCRA for Plannery to obtain consumer report information from
              my credit profile.
            </p>
          }
        />
      </FormContainer>
    </>
  );
};

export default VerifyPhoneNumber;
