import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentAccountApi, PaymentAccountParams } from 'api/PaymentAccountApi';

export const createPaymentAccountCreationThunk = (api: PaymentAccountApi) =>
  createAsyncThunk('banking/createPaymentAccount', async (params: PaymentAccountParams, thunkApi) => {
    try {
      const data = await api.createPaymentAccount(params);
      return data;
    } catch (error: unknown) {
      return thunkApi.rejectWithValue((error as Error).message);
    }
  });
