import Api from 'api/Api';
import { LinkSearchKindType } from 'enums/LinkSearchKindType';

export enum PayrollProviderName {
  Argyle = 'Argyle',
  Pinwheel = 'Pinwheel',
  Atomic = 'Atomic',
  Manual = 'Manual',
  ManuallySendToHR = 'ManuallySendToHR',
}

export interface EmployerData {
  id: string;
  name: string;
  logo_url: string;
}

export interface Employer {
  id: string;
  name: string;
  logo_url: string;
  provider: PayrollProviderName;
  kind: LinkSearchKindType;
}

export interface EmployersSearchParams {
  name: string;
  kind: LinkSearchKindType;
  providers: PayrollProviderName[];
}

export default class EmployersApi extends Api {
  public async searchEmployers({ name, kind, providers }: EmployersSearchParams): Promise<Employer[]> {
    const params = new URLSearchParams();
    params.set('name', name);
    params.set('kind', kind);
    providers.forEach((provider) => {
      params.append('provider', provider);
    });
    const results = await this.fetch<any[]>(`/employers?${params}`);
    return results.map((result) => ({ ...result, kind: result.response_type }));
  }

  public async getEmployerData(payrollProvider: string, employerId: string): Promise<EmployerData> {
    const result = await this.fetch(`/employers/${payrollProvider}/linkItem/${employerId}`);
    return result as EmployerData;
  }
}
