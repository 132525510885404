import Api, { FetchMethodType } from './Api';

export enum DocuSignDocument {
  DraftLoanAgreement = 'draftLoanAgreement',
  LoanAgreement = 'loanAgreement',
  ACH = 'ACH',
  ACHandEFT = 'ACHandEFT',
  StudentLoanAssistanceServiceAgreement = 'SLServiceAgreement',
}
export interface DataForCreatingDocuSignEnvelope {
  applicationId: string;
  windowLocationHref: string;
  document: DocuSignDocument;
}

export interface DocuSignEnvelopeApi {
  createEnvelope: (data: DataForCreatingDocuSignEnvelope) => Promise<string>;
}

export default class DocuSignEnvelopeRestApi extends Api implements DocuSignEnvelopeApi {
  public async createEnvelope(dataForCreatingDocuSignEnvelope: DataForCreatingDocuSignEnvelope) {
    const { url } = await this.fetch<{ url: string }>(`/docuSignEnvelope/${dataForCreatingDocuSignEnvelope.document}`, {
      method: FetchMethodType.POST,
      body: {
        dataForCreatingDocuSignEnvelope,
      },
    });

    return url;
  }
}
