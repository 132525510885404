export enum DebtConsolidationSummaryResult {
  Continue = 'continue',
  NotNow = 'notNow',
}

export enum DebtConsolidationEarlyAccessResult {
  Join = 'join',
  NotNow = 'notNow',
}

export type DebtConsolidationResults = DebtConsolidationSummaryResult | DebtConsolidationEarlyAccessResult;
