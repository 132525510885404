export enum ApplicationStatusName {
  OfferAvailable = 'Offer Available',
  SigningAgreement = 'Signing Agreement',
  SetUpDeduction = 'Set Up Deduction',
  Verification = 'Verification',
  ManualReview = 'Manual Review',
  FinalReview = 'Final Review',
  OutOfState = 'Out of State',
  Funded = 'Funded',
  Rejected = 'Rejected',
  ManualVerify = 'Manual Verify',
  AlreadyApplied = 'Already Applied',
}
