import React from 'react';

import styles from './ReviewCard.module.scss';

interface ReviewCardProps {
  title: string;
  icon: JSX.Element;
  content: string | JSX.Element;
}

const ReviewCard = ({ title, icon, content }: ReviewCardProps) => (
  <div className={styles.reviewContainer}>
    <div className={styles.reviewTitle}>{title}</div>
    <div className={styles.content}>
      {icon}
      <div className={styles.reviewText}>{content}</div>
    </div>
  </div>
);

export default ReviewCard;
