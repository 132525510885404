import Api, { FetchMethodType } from './Api';

export interface EmailApi {
  sendOfferComparisonEmail(applicationId: string): Promise<void>;
  sendDirectDepositEnrollmentFormEmail(applicationId: string): Promise<void>;
  sendStudentLoanEnrollmentFormEmail(applicationId: string): Promise<void>;
}

export default class EmailRestApi extends Api implements EmailApi {
  public async sendOfferComparisonEmail(applicationId: string): Promise<void> {
    await this.fetch(`/loanOffer/sendOfferComparisonEmail/${applicationId}`, {
      method: FetchMethodType.POST,
    });
  }

  public async sendDirectDepositEnrollmentFormEmail(applicationId: string): Promise<void> {
    await this.fetch(`/payrollDeduction/directDeposit/enrollment/email/${applicationId}`, {
      method: FetchMethodType.POST,
    });
  }

  public async sendStudentLoanEnrollmentFormEmail(applicationId: string): Promise<void> {
    await this.fetch(`/studentLoan/application/${applicationId}/sendEnrollmentForm`, {
      method: FetchMethodType.POST,
    });
  }
}
