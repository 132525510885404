/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StepId, StepsHook, StepProgress } from 'components/Steps/stepUtils';

import styles from './FirstTimeFlow.module.scss';

const FirstTimeFlow = <Id extends StepId, Progress extends StepProgress>(useStepsHook: StepsHook<Id, Progress>) => {
  const { stepData } = useStepsHook();
  return (
    <div className={styles.container}>
      {stepData.map((step) => (
        <div key={step.id} className={styles.step}>
          <div className={styles.stepDot} />
          <step.icon className={styles.icon} />
          <div className={styles.name}>{step.name}</div>
        </div>
      ))}
    </div>
  );
};

export default FirstTimeFlow;
