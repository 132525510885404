import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationData, StudentLoanApi } from 'api/StudentLoanApi';

export interface UpdateStudentLoanApplicationData {
  applicationId: string;
  applicationData: ApplicationData;
}

export const createUpdateStudentLoanApplicationThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/updateApplication', async (data: UpdateStudentLoanApplicationData, thunkApi) => {
    try {
      return await api.updateApplication(data.applicationId, data.applicationData);
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
