import { UrlParams } from 'enums/UrlParams';

export const getPlanneryApiUrl = () => {
  const apiRevision = getApiRevision();

  let apiUrl;
  if (apiRevision) {
    apiUrl = `https://api.staging.planneryapp.com/backend-staging/${apiRevision}`;
  } else {
    apiUrl = process.env.REACT_APP_API_URL;
  }

  return apiUrl;
};

export const getApiRevision = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const apiRevision = urlParams.get(UrlParams.ApiRevision);
  return apiRevision;
};
