import EarlyAccess from 'components/DebtConsolidation/EarlyAccess/EarlyAccess';
import OnWaitlist from 'components/DebtConsolidation/OnWaitlist/OnWaitlist';
import Summary from 'components/DebtConsolidation/Summary/Summary';
import { DebtConsolidationEarlyAccessResult, DebtConsolidationSummaryResult } from 'enums/DebtConsolidationResults';
import { RoutePath } from 'enums/Routes';

import { RouterType } from './types';
import { EMPLOYEE_LENDING_TITLE } from './DebtConsolidationRoutes';

export const getDebtConsolidationWaitlistRoutes = (): RouterType => ({
  [RoutePath.DebtConsolidationSummary]: {
    navigationInfo: { showBackLink: true, title: EMPLOYEE_LENDING_TITLE },
    component: Summary,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case DebtConsolidationSummaryResult.Continue:
          navigate(RoutePath.DebtConsolidationEarlyAccess);
          break;
        case DebtConsolidationSummaryResult.NotNow:
          navigate(RoutePath.YourFinances);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.DebtConsolidationEarlyAccess]: {
    navigationInfo: { showBackLink: true, title: EMPLOYEE_LENDING_TITLE },
    component: EarlyAccess,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case DebtConsolidationEarlyAccessResult.Join:
          navigate(RoutePath.DebtConsolidationWaitlist);
          break;
        case DebtConsolidationEarlyAccessResult.NotNow:
          navigate(RoutePath.YourFinances);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.DebtConsolidationWaitlist]: {
    navigationInfo: { showBackLink: true, title: 'Plannery Card' },
    component: OnWaitlist,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourFinances);
    },
  },
});
