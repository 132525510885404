import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YourIncomeVariable } from 'enums/LoanFormVariables';

export interface YourIncomeResponse {
  employmentIncome: Record<string, string>[];
}

export interface YourIncomeData {
  [YourIncomeVariable.TotalAnnualIncome]: number | null;
  [YourIncomeVariable.StartOfEmployment]: string | null;
  [YourIncomeVariable.EmployerName]?: string;
  [YourIncomeVariable.JobTitle]?: string;
}

export const initialState: YourIncomeData = {
  [YourIncomeVariable.TotalAnnualIncome]: null,
  [YourIncomeVariable.StartOfEmployment]: null,
};

const yourIncomeReducer = createSlice({
  name: 'yourIncome',
  initialState,
  reducers: {
    setYourIncomeData: (state: YourIncomeData, { payload }: PayloadAction<YourIncomeData>) => {
      state[YourIncomeVariable.TotalAnnualIncome] = payload[YourIncomeVariable.TotalAnnualIncome];
      state[YourIncomeVariable.StartOfEmployment] = payload[YourIncomeVariable.StartOfEmployment];
      state[YourIncomeVariable.EmployerName] = payload[YourIncomeVariable.EmployerName];
      state[YourIncomeVariable.JobTitle] = payload[YourIncomeVariable.JobTitle];
    },
  },
});

export const { setYourIncomeData } = yourIncomeReducer.actions;

export default yourIncomeReducer.reducer;
