import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingApplicationLoanManagement } from 'api/ApplicationDataApi';

import { ErrorResponse } from './ErrorResponse';

export const createLoanManagementThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('/application/loanManagement', async (data: DataForUpdatingApplicationLoanManagement, thunkApi) => {
    try {
      await api.updateApplicationLoanManagementData(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
