import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataForGettingLoanOfferXSellFromCardApplication, LoanOfferApi, LoanOfferProgress } from 'api/LoanOfferApi';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';

import { ErrorResponse } from './ErrorResponse';

export const createLoanOfferXSellThunk = (api: LoanOfferApi) =>
  createAsyncThunk(
    'loanOffer/getLoanOffer',
    async (
      data: DataForGettingLoanOfferXSellFromCardApplication & {
        updateCallback?: (data: LoanOfferProgress) => void;
      },
      thunkApi,
    ) => {
      try {
        const { updateCallback, ...dataForGettingLoanOffer } = data;

        const loanOfferResult = await api.getLoanOfferXSell(dataForGettingLoanOffer, updateCallback);

        localStorage.setItem(LocalStorageKeyName.LoanOfferResult, JSON.stringify(loanOfferResult));

        return loanOfferResult;
      } catch (error: unknown) {
        thunkApi.rejectWithValue((error as ErrorResponse).response.data);

        throw error;
      }
    },
  );

export default createLoanOfferXSellThunk;
