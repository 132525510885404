import { UrlParams } from 'enums/UrlParams';
import { RoutePath } from 'enums/Routes';

import { getApiRevision } from './getApiUrls';

/**
 * Get URI encoded pathname + any get variables of current URL so we can store it for redirection.
 */
export function getUrlPathForRedirection(): string {
  return encodeURIComponent(window.location.pathname + window.location.search);
}

/**
 * Get redirect path when we need to relogin
 */
export function getRedirectPathForLogin(): string {
  const suffix = getApiRevision() ? `&${UrlParams.ApiRevision}=${getApiRevision()}` : '';
  return `${RoutePath.SmsLogin}?${UrlParams.ComingFrom}=${getUrlPathForRedirection()}${suffix}`;
}
