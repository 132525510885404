import React from 'react';
import clsx from 'clsx';

import styles from './GraphCard.module.scss';

interface GraphCardProps {
  className: string;

  icon: React.FunctionComponent<{ className?: string }>;

  children: React.ReactNode;
}
const GraphCard = ({ icon: Icon, children, className }: GraphCardProps) => (
  <div className={clsx(styles.graphCard, className)}>
    <Icon className={styles.icon} />
    <span>{children}</span>
  </div>
);

export default GraphCard;
