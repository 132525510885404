import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KnownEmployerProfitStatus, PayoffData, StudentLoanAssistanceData } from 'api/StudentLoanApi';
import {
  getApplicationData,
  getLoanOffer,
  getStudentLoanAssistanceData,
  updateStudentLoanAssistanceData,
} from 'thunks';

import { GetApplicationDataResponse } from './applicationData';
import { LoanOfferResponse } from './loanOffer';

interface StudentLoanDataFetchStatus {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
  refetch: boolean;
}
export interface StudentLoanData {
  applicationId?: string;
  eligible?: boolean;
  paymentStartDate?: string;
  nonProfitStartDate?: string | null;
  knownEmployerProfitStatus?: KnownEmployerProfitStatus;
  allLoansMohela?: boolean;
  hasMonthlyPayments?: boolean;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  currentPath?: PayoffData;
  recommendedPath?: PayoffData;
  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

export type StudentLoanDataState = StudentLoanData & StudentLoanDataFetchStatus;

const initialState: StudentLoanDataState = {
  isLoading: false,
  error: false,
  fetched: false,
  refetch: false,
};

const updateState = (state: StudentLoanDataState, { payload }: PayloadAction<StudentLoanAssistanceData>) => {
  state.isLoading = false;
  state.fetched = true;

  state.knownEmployerProfitStatus = payload.knownEmployerProfitStatus;
  state.eligible = payload.eligible;
  state.paymentStartDate = payload.paymentStartDate;
  state.nonProfitStartDate = payload.nonProfitStartDate;
  state.maxTerm = payload.maxTerm;
  state.totalBalance = payload.totalBalance;
  state.monthSavings = payload.monthSavings;
  state.currentPath = payload.currentPath;
  state.recommendedPath = payload.recommendedPath;
  state.householdSize = payload.householdSize;
  state.undergraduateLoansPercentage = payload.undergraduateLoansPercentage;
  state.enrolledIncomeDrivenRepayment = payload.enrolledIncomeDrivenRepayment;
  state.allLoansMohela = payload.allLoansMohela;
  state.hasMonthlyPayments = payload.hasMonthlyPayments;
};

const auth = createSlice({
  name: 'studentLoanData',
  initialState,
  reducers: {
    setForProfit: (state: StudentLoanData, { payload }: PayloadAction<boolean>) => {
      state.knownEmployerProfitStatus = payload
        ? KnownEmployerProfitStatus.ForProfit
        : KnownEmployerProfitStatus.NonProfit;
    },
    setStudentLoanApplicationId: (state: StudentLoanData, { payload }: PayloadAction<string>) => {
      state.applicationId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentLoanAssistanceData.pending, (state, action) => {
      state.applicationId = action.meta.arg.applicationId;
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(getStudentLoanAssistanceData.fulfilled, updateState);
    builder.addCase(getStudentLoanAssistanceData.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.fetched = false;
      state.eligible = false;
    });
    builder.addCase(updateStudentLoanAssistanceData.pending, (state, action) => {
      state.applicationId = action.meta.arg.applicationId;
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(updateStudentLoanAssistanceData.fulfilled, updateState);
    builder.addCase(updateStudentLoanAssistanceData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.applicationId = payload.application.id;
    });
    builder.addCase(getLoanOffer.fulfilled, (state, { payload }: PayloadAction<LoanOfferResponse>) => {
      if (payload.data.application_id) {
        state.applicationId = payload.data.application_id;
      }
    });
  },
});

export const { setForProfit, setStudentLoanApplicationId } = auth.actions;

export default auth.reducer;
