import { RoutePath } from 'enums/Routes';
import CardInfo from 'components/FinancialCheckup/CardInfo';
import YourFinances from 'components/FinancialCheckup/YourFinances';

import { NextFunction, RouterType } from './types';

export const getFinancialCheckupRoutes = (): RouterType => ({
  [RoutePath.YourFinances]: {
    navigationInfo: { showBackLink: false, title: 'Financial Checkup' },
    component: YourFinances,
    handleNext: () => () => {},
  },
  [RoutePath.CardInfo]: {
    navigationInfo: { showBackLink: true, title: 'Plannery Card' },
    component: CardInfo,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.CardApplicationSuccessful);
    },
  },
});

export const backToYourFinances: NextFunction = ({ navigate }) => () => {
  navigate(RoutePath.YourFinances);
};
