import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import StateContainer from 'components/StateContainer';
import { getApplicationData } from 'selectors/getApplicationData';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { verifyApplication } from 'thunks';
import { FlowComponentType } from 'routes/types';

import styles from './VerifyingApplication.module.scss';

const VerifyingApplication = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { id: applicationId } = useSelector(getApplicationData).application!;

  useEffect(() => {
    (async () => {
      const { iovation_blackbox: iovationBlackbox } = window as any;

      if (!iovationBlackbox) {
        throw new Error('iovationBlackbox is undefined');
      }

      (window as any).nid('applicationSubmit', 'kycCheck');
      (window as any).nid('closeSession');

      const result = await dispatchWithUnwrap(verifyApplication({ applicationId, kycVariables: { iovationBlackbox } }));
      analytics.track('Verify Result', result);
      handleNext(result.status);
    })();
  }, []);

  return (
    <div className={styles.container}>
      <StateContainer icon={<LoadingSpinner />} title="Verifying..." />
    </div>
  );
};

export default VerifyingApplication;
