import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';
import { CreateApplicationData } from 'handlers/applicationData';

import { ErrorResponse } from './ErrorResponse';

export const createCreateApplicationThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/createApplication', async (applicationData: CreateApplicationData, thunkApi) => {
    try {
      const result = await api.createApplication(applicationData);

      return result;
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
