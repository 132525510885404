import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentLoanScheduleData } from 'api/StudentLoanApi';
import { setStudentLoanAssistanceSchedule } from 'thunks';

interface StudentLoanDataScheduleFetchStatus {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
  refetch: boolean;
}

type StudentLoanScheduleDataState = StudentLoanScheduleData & StudentLoanDataScheduleFetchStatus;

const initialState: StudentLoanScheduleDataState = {
  isLoading: false,
  error: false,
  fetched: false,
  refetch: false,
};

const auth = createSlice({
  name: 'studentLoanScheduleData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setStudentLoanAssistanceSchedule.pending, (state) => {
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(
      setStudentLoanAssistanceSchedule.fulfilled,
      (state, { payload }: PayloadAction<StudentLoanScheduleData>) => {
        state.isLoading = false;
        state.fetched = true;

        state.eventHost = payload.eventHost;
        state.eventHostEmail = payload.eventHostEmail;
        state.eventName = payload.eventName;
        state.eventStartTime = payload.eventStartTime;
        state.eventUri = payload.eventUri;
      },
    );
    builder.addCase(setStudentLoanAssistanceSchedule.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.fetched = false;
    });
  },
});

export default auth.reducer;
