import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataForDeclineNotification, PreQualificationDeclineApi } from 'api/PreQualificationDeclineApi';

import { ErrorResponse } from './ErrorResponse';

export const createPreQualificationDeclineThunk = (api: PreQualificationDeclineApi) =>
  createAsyncThunk(
    'preQualificationDecline/sendNotification',
    async (dataForDeclineNotification: DataForDeclineNotification, thunkApi) => {
      try {
        const data = await api.sendNotificationEmail(dataForDeclineNotification);
        return data;
      } catch (error: unknown) {
        return thunkApi.rejectWithValue((error as ErrorResponse).response);
      }
    },
  );
