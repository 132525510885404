import React, { FC } from 'react';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { GetApplicationData, HardOfferData } from 'handlers/applicationData';
import { formatNumber } from 'utils/formatNumber';

import TradelineRow from './components/TradelineRow';
import LoanBreakdownLine from './components/LoanBreakdownLine';

import styles from './LoanBreakdown.module.scss';

interface Props {
  application: GetApplicationData;
  container?: string;
  title?: string;
  subtitle?: string;
  showFees?: boolean;
  isFinal?: boolean;
}

const LoanBreakdown: FC<Props> = ({ application, container, title, subtitle, showFees = true, isFinal = false }) => {
  const { documentaryStampTaxFee, originationFee, calculatedLoanAmount, loanAmount } = application;

  const hasStateFees = (documentaryStampTaxFee ?? 0) > 0;
  const originationFeePercent = formatNumber(
    100 * ((loanAmount ?? 0) > 0 ? (originationFee ?? 0) / (loanAmount ?? 0) : 0),
    true,
  );

  const fees = (documentaryStampTaxFee ?? 0) + (originationFee ?? 0);
  if (fees === 0) {
    showFees = false;
  }

  const formattedFees = formatMonetaryAmount(fees, true);

  let totalLoanAmount = calculatedLoanAmount;
  if (!showFees) {
    totalLoanAmount! -= fees;
  }
  const formattedTotalLoanAmount = formatMonetaryAmount(totalLoanAmount, true);

  const hardOffer = application?.hardOffer as HardOfferData;
  const payOff = hardOffer?.payOff || [];
  const keepIt = hardOffer?.keepIt || [];
  const additionalFundsTotal = hardOffer?.additionalFundsTotal || {};

  const tradelinesToPay = [...payOff, ...keepIt].filter((tradeline) => tradeline.weWillPay) || [];
  const tradelinesWeWillSendYouToPay =
    [...payOff, ...keepIt].filter((tradeline) => !tradeline.weWillPay && tradeline.selectedForConsolidation) || [];
  const amountWeWillPay = tradelinesToPay.reduce((sum, tradeline) => sum + tradeline.balanceToConsolidate, 0);
  const amountWeWillSendYouToPay = tradelinesWeWillSendYouToPay.reduce(
    (sum, tradeline) => sum + tradeline.balanceToConsolidate,
    0,
  );

  const additionalFunds = additionalFundsTotal?.amount ?? 0;

  return (
    <div className={container}>
      <>
        {title && <p className={styles.title}>{title}</p>}
        <div className={styles.totalLoanAmountContainer}>
          <p className={styles.termTitle}>{subtitle || 'Total Loan Amount'}</p>
          <p className={styles.termValue}>{formattedTotalLoanAmount}</p>
        </div>
        <div className={styles.loanBreakdownDataContainer}>
          {amountWeWillPay > 0 && (
            <LoanBreakdownLine
              lineContent={
                <div className={styles.loanBreakdownLine}>
                  <p className={styles.termTitle}>We Will Pay</p>
                  <p className={styles.termValue}>{formatMonetaryAmount(amountWeWillPay, false)}</p>
                </div>
              }
              expandedContent={
                <>
                  <p>
                    After your loan is approved, we will pay off the following debts directly.
                    {!isFinal && ' You can edit these on the previous page.'}
                  </p>
                  {tradelinesToPay.map((tradeline) => (
                    <TradelineRow key={tradeline.methodAccountId} tradeline={tradeline} />
                  ))}
                </>
              }
            />
          )}
          {amountWeWillSendYouToPay > 0 && (
            <LoanBreakdownLine
              lineContent={
                <div className={styles.loanBreakdownLine}>
                  <p className={styles.termTitle}>We Will Send You To Pay</p>
                  <p className={styles.termValue}>{formatMonetaryAmount(amountWeWillSendYouToPay, false)}</p>
                </div>
              }
              expandedContent={
                <>
                  <p>
                    We will send these funds to your primary checking account after your loan is approved. You will use
                    these funds to pay off the following debts.
                  </p>
                  {tradelinesWeWillSendYouToPay.map((tradeline) => (
                    <TradelineRow key={`${tradeline.balance}${tradeline.opened}`} tradeline={tradeline} />
                  ))}
                </>
              }
            />
          )}
          {additionalFunds > 0 && (
            <LoanBreakdownLine
              lineContent={
                <div className={styles.loanBreakdownLine}>
                  <p className={styles.termTitle}>Your{amountWeWillSendYouToPay > 0 ? ' Additional' : ''} Funds</p>
                  <p className={styles.termValue}>{formatMonetaryAmount(additionalFunds, false)}</p>
                </div>
              }
              expandedContent={
                <>
                  <p>
                    These funds will go to your checking account, which you can use to pay off any other expensive debt
                    that we don’t have visibility into, or as an emergency fund.
                  </p>
                </>
              }
            />
          )}
          {showFees && (
            <LoanBreakdownLine
              lineContent={
                <div className={styles.loanBreakdownLine}>
                  <p className={styles.termTitle}>Fees</p>
                  <p className={styles.termValue}>{formattedFees}</p>
                </div>
              }
              expandedContent={
                <>
                  <p>
                    This include {hasStateFees ? 'any applicable state fees and' : ''} a {originationFeePercent}%
                    origination fee that covers our costs. The fees are added to the final loan amount and are not
                    charged upfront.
                  </p>
                  <div className={styles.tradelineContainer}>
                    <p className={styles.termTitle}>Origination Fee</p>
                    <p className={styles.termValue}>{formatMonetaryAmount(originationFee, true)}</p>
                  </div>
                  {(documentaryStampTaxFee ?? 0) > 0 && (
                    <div className={styles.tradelineContainer}>
                      <p className={styles.termTitle}>Florida State Fee</p>
                      <p className={styles.termValue}>{formatMonetaryAmount(documentaryStampTaxFee, true)}</p>
                    </div>
                  )}
                </>
              }
            />
          )}
        </div>
      </>
    </div>
  );
};

export default LoanBreakdown;
