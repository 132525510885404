import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';
import { GetApplicationAprResponse } from 'handlers/applicationData';

import { ErrorResponse } from './ErrorResponse';

export const createGetApplicationAprThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/getApplicationApr', async (applicationId: string, thunkApi) => {
    try {
      const applicationApr: GetApplicationAprResponse = await api.getApplicationApr(applicationId);
      return applicationApr;
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);
      throw error;
    }
  });
