import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi, StudentLoanCalculationParameters } from 'api/StudentLoanApi';

export type StudentLoanAssumptionsInputData = {
  applicationId: string;
} & StudentLoanCalculationParameters;

export const createUpdateStudentLoanAssistanceDataThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/updateAssistanceData', async (data: StudentLoanAssumptionsInputData, thunkApi) => {
    try {
      const { applicationId, ...rest } = data;
      return await api.updateStudentLoanAssistantData(applicationId, rest);
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
