import React from 'react';
import clsx from 'clsx';

import styles from './RadioButtonList.module.scss';

interface RadioButtonListProps {
  customization?: {
    smallCheckboxes?: boolean;
  };
  options: {
    id: string;
    label: string | React.ReactNode;
  }[];
  label?: string;
  selected: string | null;
  setSelected: (id: any) => void;
}

const RadioButtonList = ({ customization, label, options, selected, setSelected }: RadioButtonListProps) => (
  <div className={styles.container}>
    {label && <p className={styles.label}>{label}</p>}
    {options.map((option) => (
      <div key={option.id} className={styles.option} onClick={() => setSelected(option.id)}>
        <div
          className={clsx(styles.radio, {
            [styles.small]: customization?.smallCheckboxes ?? false,
            [styles.selected]: selected === option.id,
          })}
        />
        <p className={styles.label}>{option.label}</p>
      </div>
    ))}
  </div>
);

export default RadioButtonList;
