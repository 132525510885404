import { EnhancedStore } from '@reduxjs/toolkit';
import { RootState } from 'handlers';

let store: EnhancedStore<RootState>;

export const injectStore = (_store: EnhancedStore<RootState>) => {
  store = _store;
};

/**
 * call this function elsewhere to access store state directly outside of React components.  Do not import store directly
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */
export const getInjectedStoreState = (): RootState => store.getState();
