import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, UpdatedEmploymentData } from 'api/ApplicationDataApi';
import { GetApplicationDataResponse } from 'handlers/applicationData';

export const createUpdateEmploymentDataThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/updateEmploymentData', async (data: UpdatedEmploymentData, thunkApi) => {
    try {
      const application: GetApplicationDataResponse = await api.updateEmploymentData(data);
      return application;
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
