import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateAuthSessionData, CreateElementTokenData, MethodApi } from 'api/MethodApi';

export const createElementTokenThunk = (api: MethodApi) =>
  createAsyncThunk('method/createElementToken', async (data: CreateElementTokenData, thunkApi) => {
    try {
      return await api.createElementToken(data);
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.data);
    }
  });

export const createAuthSessionThunk = (api: MethodApi) =>
  createAsyncThunk('method/createAuthSession', async (data: CreateAuthSessionData, thunkApi) => {
    try {
      return await api.createAuthSession(data);
    } catch (error: any) {
      return thunkApi.rejectWithValue({ status: error.responseStatus, ...error.data });
    }
  });
